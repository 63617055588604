import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";
import { getScoreIcon } from "../utils";
import { SimpleBar } from "./SimpleBar";

const scoreStyle: any = {
  high: {
    className: "text-v2-success",
    icon: getScoreIcon("high"),
  },
  medium: {
    className: "text-v2-warning",
    icon: getScoreIcon("medium"),
  },
  low: {
    className: "text-v2-danger",
    icon: getScoreIcon("low"),
  },
} as const;

export const ActivityCard = ({ scoreDetail }: any) => {
  const { activityScore } = scoreDetail;
  const { className, icon } = scoreStyle[activityScore.activity];

  return (
    <div
      className="border-stroke-background bg-primary w-96 rounded-xl border p-6 shadow-lg"
      data-testid="activity-score-card"
    >
      <div className="border-primary flex items-center justify-between border-b pb-6">
        <div className="text-default flex items-center gap-x-1">
          <Typography.LabelMd weight="semibold">
            {t("activity_score.activity_score_title")}
          </Typography.LabelMd>
          <TippyV2 content={t("activity_score.description")}>
            <Icon type="16-info" useWithStrokeCurrentColor size={16} />
          </TippyV2>
        </div>
        <div className={classNames("flex items-center gap-x-2", className)}>
          <Icon type={icon} aria-hidden size={20} />
          <Typography.LabelLg weight="semibold" color="currentColor">
            {activityScore.score}
          </Typography.LabelLg>
        </div>
      </div>
      <div className="flex flex-col gap-y-6 pt-6">
        {scoreDetail.scoreBreakdown?.map(
          ({ category, display_value, score, activity }: any) => (
            <SimpleBar
              key={display_value}
              label={t(["activity_score.activity_card", category])}
              score={score}
              scoreType={activity}
            />
          ),
        )}
      </div>
    </div>
  );
};
