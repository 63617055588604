import { useEffect, useState } from "react";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  extractHtmlContent,
  isHtmlContent,
  tokenizeHtml,
} from "@circle-react/helpers/htmlContent";
import { SanitizeHTMLString } from "@circle-react-uikit/SanitizeContent";

interface TypingEffectProps {
  text: string;
  updateInterval?: number;
  active?: boolean;
  onComplete?: () => void;
}

export const TypingEffect = ({
  text,
  updateInterval = 60,
  active = true,
  onComplete = noop,
}: TypingEffectProps) => {
  const [currentTokenIndex, setCurrentTokenIndex] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [tokens, setTokens] = useState<string[]>([]);

  useEffect(() => {
    if (text && active) {
      if (isHtmlContent(text)) {
        const htmlContent = extractHtmlContent(text);
        setTokens(tokenizeHtml(htmlContent));
      } else {
        setTokens(text.match(/\S+\s*/g) || []);
      }

      setCurrentTokenIndex(0);
    } else {
      setTokens([]);
      setIsComplete(true);
      onComplete();
    }
  }, [text, active, onComplete]);

  useEffect(() => {
    if (!tokens.length || currentTokenIndex >= tokens.length) {
      if (tokens.length && currentTokenIndex >= tokens.length) {
        setIsComplete(true);
        onComplete();
      }
      return;
    }

    const timer = setTimeout(() => {
      setCurrentTokenIndex(prev => prev + 1);
    }, updateInterval);

    return () => clearTimeout(timer);
  }, [tokens, currentTokenIndex, updateInterval, onComplete]);

  const visibleTokens = tokens.slice(0, currentTokenIndex);

  return (
    <span className="whitespace-pre-wrap">
      {active ? (
        <>
          <SanitizeHTMLString content={visibleTokens.join("")} />
          {!isComplete && (
            <span className="animate-blink-typing-cursor ml-[2px]">
              {t("typing_cursor")}
            </span>
          )}
        </>
      ) : (
        <SanitizeHTMLString content={extractHtmlContent(text)} />
      )}
    </span>
  );
};
