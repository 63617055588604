import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

export const useCommunityMemberSearch = (
  query,
  { excludeCommunityMemberIds = [], enabled = true } = {},
) => {
  const { data } = useQuery(
    internalApi.search.communityMembers.index({
      params: {
        query,
        filters: {
          exclude_ids: excludeCommunityMemberIds,
        },
      },
    }),
    {
      enabled: enabled && !!query?.length,
    },
  );

  return {
    records: data?.records || [],
  };
};
