interface FadingWordProps {
  isVisible: boolean;
  children: React.ReactNode;
}

export const FadingWord = ({ isVisible, children }: FadingWordProps) => (
  <span className={isVisible ? "animate-fade-in-slow" : "hidden"}>
    {children}
  </span>
);
