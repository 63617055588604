import { useProfileLinkProps } from "@/react/hooks/profile/useProfileLinkProps";

interface ProfileLinkProps {
  name: string;
  id: string;
}

export const ProfileLink = ({ name, id }: ProfileLinkProps) => {
  const linkProps = useProfileLinkProps({
    name,
    public_uid: id,
    params: { show_back_link: true },
  });

  return (
    <a {...linkProps} style={{ display: "inline" }}>
      {name}
    </a>
  );
};
