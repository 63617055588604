import { VirtualAnchorPopup } from "../../VirtualAnchorPopup";
import { useEditorApi } from "../TrixEditorContext";
import { useEditorMode } from "../useEditorMode";

export const HyperlinkTooltips = () => {
  const { showHyperlinkTooltip, startPosition } = useEditorMode();
  const { trixApiWrapper } = useEditorApi();
  const show = showHyperlinkTooltip();

  if (!show) {
    return null;
  }

  const rect = trixApiWrapper.getClientRectAtPosition(startPosition);
  const href = trixApiWrapper.getHrefAtPosition(startPosition);

  return (
    <VirtualAnchorPopup show anchorRect={rect}>
      <div className="inline-toolbar">
        <a
          href={href}
          target="_blank"
          className="inline-toolbar__hyperlink-tooltip"
          rel="noreferrer"
        >
          {href}
        </a>
      </div>
    </VirtualAnchorPopup>
  );
};
