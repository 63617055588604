/* Code extracted from the original library as the handleEvent funcs was not exported
 * https://github.com/basecamp/trix/blob/main/src/trix/core/helpers/dom.js
 */

const html = document.documentElement;
const match = html.matches;

export const handleEvent = function (
  eventName,
  {
    onElement,
    matchingSelector,
    withCallback,
    inPhase,
    preventDefault,
    times,
  } = {},
) {
  const element = onElement ? onElement : html;
  const selector = matchingSelector;
  const useCapture = inPhase === "capturing";

  const handler = function (event) {
    if (times != null && --times === 0) {
      handler.destroy();
    }
    const target = findClosestElementFromNode(event.target, {
      matchingSelector: selector,
    });
    if (target != null) {
      withCallback?.call(target, event, target);
      if (preventDefault) {
        event.preventDefault();
      }
    }
  };

  handler.destroy = () =>
    element.removeEventListener(eventName, handler, useCapture);

  element.addEventListener(eventName, handler, useCapture);
  return handler;
};

const findClosestElementFromNode = function (
  node,
  { matchingSelector, untilNode } = {},
) {
  while (node && node.nodeType !== Node.ELEMENT_NODE) {
    node = node.parentNode;
  }
  if (node == null) {
    return;
  }

  if (matchingSelector != null) {
    if (node.closest && untilNode == null) {
      return node.closest(matchingSelector);
    }
    while (node && node !== untilNode) {
      if (elementMatchesSelector(node, matchingSelector)) {
        return node;
      }
      node = node.parentNode;
    }
  } else {
    return node;
  }
};

const elementMatchesSelector = function (element, selector) {
  if (element?.nodeType === 1) {
    return match.call(element, selector);
  }
};
