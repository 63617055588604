import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";

interface CollapsedFooterProps {
  onExpandClick: () => void;
}

export const CollapsedFooter = ({ onExpandClick }: CollapsedFooterProps) => (
  <div className="absolute bottom-4 left-5">
    <Button
      variant="secondary"
      type="button"
      onClick={onExpandClick}
      className="!border-primary w-fit !text-xs opacity-0 transition-opacity delay-0 duration-300 ease-in-out group-hover:opacity-100 group-hover:delay-200"
    >
      {t("expand")}
    </Button>
  </div>
);
