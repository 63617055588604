import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";
import { useCommunityMemberPickerContext } from "../CommunityMemberPickerContext";
import { SearchResults } from "../SearchResults";
import { useCommunityMemberSearch, useGeneralSearch } from "./hooks";

const QUERY_DEBOUNCE_MILLISECONDS = 300;

export const Search = ({
  placeholder = "",
  className,
  selectionModalRef,
  disableSearch = false,
  ...rest
}) => {
  const {
    onAdd,
    excludeCommunityMemberIds = [],
    includeAllResultTypes,
  } = useCommunityMemberPickerContext();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebouncedValue(query, QUERY_DEBOUNCE_MILLISECONDS);
  const emptyOrDebouncedQuery = !query ? query : debouncedQuery;
  const { records: communityMemberRecords } = useCommunityMemberSearch(
    emptyOrDebouncedQuery,
    {
      excludeCommunityMemberIds,
      enabled: !includeAllResultTypes,
    },
  );
  const { records: generalRecords } = useGeneralSearch(emptyOrDebouncedQuery, {
    enabled: includeAllResultTypes,
  });
  const records = includeAllResultTypes
    ? generalRecords
    : communityMemberRecords;
  const onSelect = record => {
    onAdd(record);
    setQuery("");
  };
  const handleReset = () => setQuery("");

  return (
    <div
      className={classNames("relative overflow-visible", className)}
      {...rest}
    >
      <input
        className="border-primary bg-primary text-dark placeholder:text-light w-full rounded-lg border px-4 py-3 placeholder:opacity-100"
        type="text"
        placeholder={placeholder}
        autoFocus
        value={query}
        onChange={e => setQuery(e.target.value)}
        disabled={disableSearch}
      />
      <SearchResults
        records={records}
        onSelect={onSelect}
        onReset={handleReset}
        selectionModalRef={selectionModalRef}
        includeAllResultTypes={includeAllResultTypes}
      />
    </div>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  selectionModalRef: PropTypes.any,
  disableSearch: PropTypes.bool,
};
