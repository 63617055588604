import PropTypes from "prop-types";
import classNames from "classnames";

export const Sidebar = ({ children, className = "", ...rest }) => (
  <aside
    className={classNames(
      "bg-primary border-primary grid-in-sidebar h-full w-full overflow-y-auto border-r",
      className,
    )}
    data-testid="side-menu-bar"
    {...rest}
  >
    {children}
  </aside>
);

Sidebar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};
