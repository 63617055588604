import { useEffect } from "react";
import { noop } from "lodash";
import { useTrixEditorFeatureFlagContext } from "@circle-react-shared/TrixEditor/TrixEditorFeatureFlagContext";
import { AttachmentUpload } from "./AttachmentUpload";

export const useAttachments = editorRef => {
  const { isTrixFeatureEnabled } = useTrixEditorFeatureFlagContext();

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }
    const mayBeRejectFile = event => {
      if (!event.file) {
        event.preventDefault();
      } else if (event.file.type.startsWith("video")) {
        if (!isTrixFeatureEnabled("videoUpload")) {
          event.preventDefault();
          console.error("Video upload is disabled!");
        }
        // eslint-disable-next-line sonarjs/no-collapsible-if -- Disabled to set CI to fail on this issue on new files, PR #6718
      } else if (!event.file.type.startsWith("image")) {
        if (!isTrixFeatureEnabled("fileUploadLink")) {
          event.preventDefault();
          console.error("File upload is disabled!");
        }
      }
    };
    const { current } = editorRef;
    current.addEventListener("trix-file-accept", mayBeRejectFile);

    return () =>
      current.removeEventListener("trix-file-accept", mayBeRejectFile);
  }, [editorRef, isTrixFeatureEnabled]);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }
    const { current } = editorRef;

    const uploadFile = event => {
      const { attachment } = event;
      event.preventDefault();
      if (attachment.file) {
        const uploader = new AttachmentUpload(attachment, current, noop);
        uploader.start();
      }
    };

    current.addEventListener("trix-attachment-add", uploadFile);
    return () => current.removeEventListener("trix-attachment-add", uploadFile);
  }, [editorRef]);
};
