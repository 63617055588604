import PropTypes from "prop-types";
import classNames from "classnames";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";

export const Layout = ({
  children,
  className = "",
  variant = "default",
  ...rest
}) => {
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();
  return (
    <div
      className={classNames(
        "grid-rows-customize-ui grid-cols-customize-ui grid h-full w-full overflow-hidden",
        {
          "grid-areas-customize-ui": variant === "default",
          "!grid-cols-customize-ui-v2-1":
            isMobileWebNewSpaceCustomizationUIEnabled,
          "grid-areas-customize-ui-without-sidebar": variant === "no-sidebar",
          "grid-areas-customize-ui-without-header": variant === "no-header",
          "grid-areas-customize-ui-only-main": variant === "only-main",
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf(["default", "no-sidebar", "no-header", "only-main"]),
};
