import { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useClickAway } from "react-use";
import { useDetectEscapeKeyPress } from "../../../hooks/useDetectEscapeKeyPress";
import { ModalBody } from "./ModalBody";
import { ModalHeader } from "./ModalHeader";

export * from "./ModalBody";
export * from "./ModalHeader";

/** @deprecated Please use the new modal at "@circle-react-uikit/ModalV2" */
export const Modal_DEPRECATED = ({
  children,
  show,
  fullscreen = false,
  className,
  innerClassName,
  closeOnEscape = false,
  closeOnOutsideClick = false,
  onClose,
  trixMode,
}) => {
  const modalRef = useRef(null);

  const mayBeCloseOnEscape = () => {
    closeOnEscape && show && onClose();
  };

  const mayBeCloseOnOutsideClick = () => {
    closeOnOutsideClick && show && onClose();
  };

  useDetectEscapeKeyPress(mayBeCloseOnEscape);

  useClickAway(modalRef, mayBeCloseOnOutsideClick);

  if (!show) return null;

  const containerClassNames = classnames(`modal__box ${trixMode}`, className, {
    modal__fullscreen: fullscreen,
  });
  return (
    <>
      <div className={containerClassNames}>
        <div
          ref={modalRef}
          className={classnames("modal__inner", innerClassName)}
        >
          {children}
        </div>
      </div>
      <div className="modal-background !bg-gray-900/50" />
    </>
  );
};

Modal_DEPRECATED.Header = ModalHeader;
Modal_DEPRECATED.Body = ModalBody;

Modal_DEPRECATED.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  fullscreen: PropTypes.bool,
  innerClassName: PropTypes.string,
  className: PropTypes.string,
  closeOnEscape: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  onClose: PropTypes.func,
  trixMode: PropTypes.bool,
};
