import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import type { IconType } from "@circle-react-shared/Icon";
import { RadioButton } from "./RadioButton";

export interface Option {
  value: string;
  label: ReactNode | string;
  icon?: IconType;
  iconSize?: number;
}

export interface RadioButtonGroupProps {
  name: string;
  options: Option[];
  defaultChecked: string;
  onChange?: (value: any) => void;
  size?: "sm" | "md";
  hasRadioCheckmarks?: boolean;
  shouldShowInlineOptions?: boolean;
  checkedOptionClassName?: string;
}

export const RadioButtonGroup = ({
  name,
  options,
  defaultChecked,
  onChange,
  size = "md",
  hasRadioCheckmarks = false,
  shouldShowInlineOptions = false,
  checkedOptionClassName = "",
}: RadioButtonGroupProps) => {
  const [checkedOption, setCheckedOption] = useState(defaultChecked);

  useEffect(() => {
    if (checkedOption && onChange) {
      onChange(checkedOption);
    }
  }, [onChange, checkedOption]);

  return (
    <div
      className={classNames("flex", {
        "flex-col gap-3": !shouldShowInlineOptions,
        "flex-inline justify-center gap-4 text-center": shouldShowInlineOptions,
      })}
    >
      {options.map(({ value, label, icon, iconSize }, index) => (
        <RadioButton
          data-testid={`radio-button-group-${index}`}
          isChecked={value === checkedOption}
          onChange={setCheckedOption}
          key={value}
          label={label}
          name={name}
          id={`${name}-${index}`}
          value={value}
          size={size}
          hasRadioCheckmarks={hasRadioCheckmarks}
          icon={icon}
          iconSize={iconSize}
          checkedOptionClassName={checkedOptionClassName}
        />
      ))}
    </div>
  );
};
