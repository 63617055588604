import { useRef } from "react";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { ButtonWithDisabled } from "@circle-react-uikit/ButtonWithDisabled";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { ScheduledEmailForm } from "./Form";
import { useScheduledEmailModalStore } from "./store";
import { useScheduledEmailsForm } from "./useScheduledEmailsForm";

export type ScheduledEmailType = "event" | "post";

export interface ScheduledEmail {
  id?: string;
  record_id: string;
  scheduled_email_type: ScheduledEmailType;
  scheduled_at: Date;
  subject: string;
  email_body: Record<string, any>;
  enabled: boolean;
  metadata: Record<string, string>;
}

interface ScheduledEmailModalProps {
  scheduledEmail: ScheduledEmail;
  isOpen: boolean;
  onClose: () => void;
  maxDate?: Date;
  minDate?: Date;
}

export const ScheduledEmailModal = ({
  scheduledEmail,
  isOpen,
  onClose,
  maxDate,
  minDate,
}: ScheduledEmailModalProps) => {
  const initialFocusRef = useRef(null);
  const { isDirty, setIsDirty } = useScheduledEmailModalStore();
  const { saveScheduledEmail, isLoading } = useScheduledEmailsForm();

  const handleSubmit = async (params: Record<string, any>) => {
    try {
      await saveScheduledEmail(params);
      setIsDirty(false);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const id = scheduledEmail.id || "";

  const FORM_ID = `edit-scheduled-email-${id}`;

  const onCloseWithConfirm = () => {
    /* eslint-disable-next-line no-alert --
       We use a very obtrusive confirm dialog to make sure the
       user is aware of the consequences of closing the modal
     */
    if (!isDirty || confirm(t("prevent_accidental_leave_message"))) {
      onClose();
    }
  };

  return (
    <Form
      id={FORM_ID}
      onSubmit={handleSubmit}
      defaultValues={scheduledEmail}
      shouldStopPropagation
    >
      <Modal
        isOpen={isOpen}
        onClose={onCloseWithConfirm}
        contentPortalId="scheduled-email-type-picker-portal"
        initialFocus={initialFocusRef}
        className="!h-full"
      >
        <Modal.Overlay />
        <Modal.Content size="lg">
          <Modal.Header>
            <Modal.Title size="md">
              {t("scheduled_emails.form.title")}
            </Modal.Title>
            <Modal.HeaderActions>
              <Modal.CloseButton onClick={onCloseWithConfirm} />
            </Modal.HeaderActions>
          </Modal.Header>
          <Modal.Body>
            <ScheduledEmailForm maxDate={maxDate} minDate={minDate} />
          </Modal.Body>
          <Modal.Footer className="flex justify-end">
            {!isLoading && (
              <Button
                type="button"
                variant="secondary"
                className="mr-2"
                onClick={onCloseWithConfirm}
              >
                {t("cancel")}
              </Button>
            )}
            <ButtonWithDisabled
              type="submit"
              variant="circle"
              form={FORM_ID}
              isDisabled={isLoading}
            >
              {!isLoading && t("save")}
              {isLoading && t("saving")}
            </ButtonWithDisabled>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Form>
  );
};
