import type { PropsWithChildren } from "react";
import classNames from "classnames";

interface MainMobileProp {
  className?: string;
}

export const MainMobile = ({
  children,
  className = "",
  ...rest
}: PropsWithChildren<MainMobileProp>) => (
  <main
    className={classNames("bg-secondary grid-in-main h-full w-full", className)}
    {...rest}
  >
    <div className="flex h-full w-full items-center justify-center">
      {children}
    </div>
  </main>
);
