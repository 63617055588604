import { useEffect } from "react";
import Tribute from "tributejs";
import Trix from "trix";
import {
  communityMemberProfilePath,
  mentions,
} from "@circle-react/helpers/urlHelpers";

const MENTION_CONTENT_TYPE = "mention";

const buildMentionAttachmentContent = ({ id, public_uid, name }) => {
  const memberProfilePath = communityMemberProfilePath({
    public_uid: public_uid,
    params: { show_back_link: true },
  });

  const mentionLinkEl = document.createElement("a");
  mentionLinkEl.className = "mention__name";
  mentionLinkEl.setAttribute("role", "button");
  mentionLinkEl.setAttribute("href", memberProfilePath);
  mentionLinkEl.textContent = name;

  const mentionContainerEl = document.createElement("span");
  mentionContainerEl.className = `mention user_mention_${id}`;
  mentionContainerEl.appendChild(mentionLinkEl);

  return mentionContainerEl.outerHTML;
};

export const useMentions = (editor, props) => {
  const insertMention = user => {
    const editorElement = editor.current;
    const attachment = new Trix.Attachment({
      content: user.content ?? buildMentionAttachmentContent(user),
      contentType: MENTION_CONTENT_TYPE,
      sgid: user.sgid,
      name: user.name,
    });
    editorElement.editor.insertAttachment(attachment);
    editorElement.editor.insertString(" ");
  };

  const shouldInsert = sgid => {
    const attachments = editor.current.editor.composition.attachments;
    if (attachments.length == 0 && editor.current.innerText.trim() == "") {
      return true;
    }
    return (
      attachments.length == 1 &&
      attachments[0].attributes.values.sgid != sgid &&
      editor.current.innerText.trim() == attachments[0].attributes.values.name
    );
  };

  const removeMentions = () => {
    const editorComposition = editor.current.editor.composition;

    editorComposition.attachments
      .filter(
        attachment =>
          attachment.attributes.values.contentType === MENTION_CONTENT_TYPE,
      )
      .forEach(attachment => editorComposition.removeAttachment(attachment));
  };

  useEffect(() => {
    const editorElement = editor.current;
    if (!editorElement) {
      return;
    }

    const fetchUsers = (query, callback) => {
      const params = { query, is_private_space: !!props.is_private_space };
      if (props.space_id) {
        params.space_id = props.space_id;
      }
      fetch(mentions.index({ params }))
        .then(response => response.json())
        .then(users => callback(users))
        .catch(() => callback([]));
    };

    const replaced = e => {
      const user = e.detail.item.original;
      const editorElement = editor.current;
      const attachment = new Trix.Attachment({
        content: user.content,
        contentType: MENTION_CONTENT_TYPE,
        sgid: user.sgid,
      });
      editorElement.editor.insertAttachment(attachment);
      editorElement.editor.insertString(" ");
    };

    const pasteHtml = (html, startPos, endPos) => {
      const position = editorElement.editor.getPosition();
      editorElement.editor.setSelectedRange([position - endPos, position]);
      editorElement.editor.deleteInDirection("backward");
    };
    const tribute = new Tribute({
      allowSpaces: true,
      lookup: "just_name",
      values: fetchUsers,
      menuItemTemplate: function (item) {
        return item.original.name;
      },
    });
    tribute.attach(editorElement);
    editorElement.addEventListener("tribute-replaced", replaced);
    tribute.range.pasteHtml = pasteHtml;
    return () => {
      tribute.detach(editorElement);
      editorElement.removeEventListener("tribute-replaced", replaced);
    };
  }, [editor, props.is_private_space, props.space_id]);

  return {
    insertMention,
    shouldInsert,
    removeMentions,
  };
};
