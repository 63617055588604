import classNames from "classnames";
import { useCommunityAiUpgradeModal } from "@/react/components/SettingsApp/CommunityAi/UpgradeModal";
import { useShouldShowCommunityAiModal } from "@circle-react/hooks/useIsActivityScoreEnabled";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";

export const Container = ({ className, children }: any) => {
  const shouldShowCommunityAiModal = useShouldShowCommunityAiModal();
  const communityAiModal = useCommunityAiUpgradeModal();

  const handleClick = async (e: any) => {
    e.stopPropagation();
    await communityAiModal.show({
      source: UPGRADE_FLOW_SOURCES.ACTIVITY_SCORES_SCORE_CHIP,
    });
  };

  const renderComponent = () => {
    const commonProps = {
      className: classNames(
        "inline-flex items-center justify-center gap-x-2 rounded-3xl border px-2.5 py-1.5",
        className,
      ),
      onClick: shouldShowCommunityAiModal ? handleClick : undefined,
    };

    if (shouldShowCommunityAiModal) {
      return (
        <button {...commonProps} type="button">
          {children}
        </button>
      );
    }
    return <div {...commonProps}>{children}</div>;
  };

  return renderComponent();
};
