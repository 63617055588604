import { ScreenSizeSelect } from "@circle-react-shared/ScreenSizeSelect";
import { ExitButton } from "./ExitButton";
import { Header as CustomizeUIHeader } from "./Header";
import { HeaderActions } from "./HeaderActions";
import { LinkButton } from "./LinkButton";
import { SaveButton } from "./SaveButton";

export const Header = Object.assign(CustomizeUIHeader, {
  Actions: HeaderActions,
  ExitButton,
  LinkButton,
  SaveButton,
  ScreenSizeSelect,
});
