/*
  Ids used on this file:
  /app/assets/images/sprite/icons.svg
*/
export const ICONS_3 = [
  "16-artificial-intelligence",
  "29-artificial-intelligence",
  "16-ask-ai-gradient",
  "20-ask-ai-gradient",
  "20-stardust-gradient",
  "16-thunder",
  "16-arrow-enter",
  "16-members-bis",
  "16-camera",
  "16-pin-sm",
  "20-checkmark",
  "20-microphone",
  "20-retry",
  "16-calendar-recurring",
  "20-space-picker-members",
  "20-alert",
  "24-search-v2",
  "32-search-v2",
  "12-circle-check",
  "icon-customize-layout-feed",
  "icon-customize-layout-list",
  "16-chevron-right",
  "16-chevron-up",
  "16-repeat",
  "20-editor-poll",
  "20-pinned-post",
  "20-images",
  "16-post-list-like",
  "16-post-list-comment",
  "16-star-ai",
  "24-circle-arrow-up",
  "event-layout-thumbnail",
  "event-layout-list",
  "16-image-square",
  "16-image-portrait",
  "16-image-landscape",
  "16-zoom-out",
  "16-zoom-in",
  "16-bookmark",
  "16-bookmark-fill",
  "20-bookmark",
  "20-bookmark-fill",
  "16-microphone",
  "16-microphone-muted",
  "20-tiptap-entity",
  "12-globe",
  "20-filters",
  "16-thumbnail-layout",
  "14-magic-ai",
  "16-disabled",
  "16-crop",
  "16-reaction",
  "20-card-view-mobile",
  "20-feed",
  "20-masonry",
  "20-grid",
  "16-multiple-images",
  "16-join-date",
  "16-live",
  "16-video",
  "20-attachment",
  "20-gif-2",
  "20-image-2",
  "20-video-2",
  "20-hash",
  "20-mention",
  "20-mic-2",
  "20-reaction",
  "20-text-formatting",
  "16-send",
  "20-send",
  "20-checklist",
  "course-files",
  "20-location",
  "20-camera",
  "10-circle-check",
  "20-calendar",
  "24-close",
  "16-reorder",
  "16-refresh",
  "20-menu-dots-vertical",
  "16-pin-sm-white",
  "16-restart",
  "16-check",
  "16-link",
  "12-chevron-right",
  "12-chevron-left",
  "20-pix",
  "20-pin-post",
  "16-trash-bin-2",
  "16-message",
  "20-email-broadcast",
  "16-welcome-banner",
  "16-document-text",
  "align-img-left",
  "align-img-right",
  "align-img-center",
  "align-img-full",
  "20-delay-time",
  "20-event-ticket",
  "16-event-ticket",
  "20-share",
  "20-search-v3",
  "20-bell-v3",
  "20-message-v3",
  "20-thread-v3",
  "20-bookmark-v3",
  "20-todo-list-v3",
  "20-feed-v3",
  "20-three-dots-vertical-v3",
  "20-three-dots-horizontal-v3",
  "12-chevron-down-v3",
  "20-image-v3",
  "20-customize-v3",
  "20-card-v3",
  "20-locker-closed-v3",
  "20-info-v3",
  "20-user-plus-v3",
  "20-sign-out-v3",
  "20-users-v3",
  "20-doc-v3",
  "20-spaces-v3",
  "20-shield-v3",
  "20-live-v3",
  "20-flash-v3",
  "20-currency-v3",
  "20-analytics-v3",
  "20-affiliates-v3",
  "20-settings-v3",
  "20-moon-v3",
  "20-color-palette-v3",
  "20-open-eye-v3",
  "20-folder-v3",
  "20-user-v3",
  "20-edit-v3",
  "20-bell-v3",
  "20-locker-open-v3",
  "20-book-v3",
  "20-bin-v3",
  "20-plus-v3",
  "20-welcome-banner-v3",
  "event-layout-calendar",
  "customize-layout-feed",
  "customize-layout-list",
  "post-2",
  "event",
  "chat-2",
  "spaceship-blue",
  "new-file-plus",
  "star-orange",
  "16-capital-text",
  "24-info",
  "24-radio-checked",
  "24-radio-unchecked",
  "20-checkmark-circle-fill-dark",
  "leaderboard-progress",
  "leaderboard-activity",
  "leaderboard-levelup",
  "leaderboard-rank-1",
  "leaderboard-rank-2",
  "leaderboard-rank-3",
  "leaderboard-rank-1-dark",
  "leaderboard-rank-2-dark",
  "leaderboard-rank-3-dark",
  "20-event-clock",
  "20-event-members",
  "24-heart-outline",
  "24-heart-red-fill",
  "24-comment-v3",
  "16-heading1",
  "20-community-ai",
  "16-editor-h2",
  "16-editor-h3",
  "16-editor-mention",
  "16-editor-image",
  "16-editor-file",
  "16-editor-audio",
  "16-editor-video",
  "16-editor-pdf",
  "16-editor-giphy",
  "16-editor-embed",
  "16-editor-ul",
  "16-editor-ol",
  "16-editor-emoji",
  "16-editor-poll",
  "16-editor-code",
  "16-editor-divider",
  "16-editor-paragraph",
  "16-editor-quote",
  "16-editor-youtube",
  "16-editor-wistia",
  "16-editor-vimeo",
  "16-editor-loom",
  "16-editor-soundcloud",
  "16-editor-spotify",
  "16-editor-simplecast",
  "16-editor-instagram",
  "16-editor-facebook",
  "16-editor-twitter",
  "16-editor-typeform",
  "16-editor-airtable",
  "16-editor-github",
  "16-editor-google-maps",
  "16-editor-codepen",
  "20-link",
  "20-checkbox",
  "16-bold",
  "16-italic",
  "16-string-text",
  "16-edit-underline",
  "20-reaction",
  "24-comment",
  "16-image-left",
  "16-image-right",
  "16-image-centre",
  "16-image-full-width",
  "16-trash",
  "link-pay",
  "20-workflows-lightning",
  "20-keyboard-shortcuts",
  "24-keyboard-shortcuts",
  "16-workflows-lightning-filled",
  "16-workflows-lightning-filled-gray",
  "12-warning",
  "20-flux-bookmark",
  "20-flux-search",
  "12-chevron-down",
  "16-workflow-fit-to-container",
  "16-workflow-zoom-in",
  "16-workflow-zoom-out",
  "16-arrow-up",
  "16-arrow-down",
  "16-payments",
  "courses-dashboard",
  "courses-comments",
  "courses-paywalls",
  "courses-community",
  "courses-video-hosting",
  "22-warning-i",
  "20-display",
  "20-docs",
  "20-stack-dev",
  "20-code-tag",
  "20-site",
  "16-site-link",
  "16-star",
  "20-blocked",
  "16-star-fill",
  "24-threads",
  "20-users-plus",
  "16-required-off",
  "16-required-on",
  "14-thumbs-up",
  "14-reply-in-space",
  "16-google-calendar",
  "16-minus",
  "16-reply-v3",
  "16-post-v3",
  "16-pools-v3",
  "16-message-v3",
  "16-mention-v3",
  "16-live-v3",
  "16-like-v3",
  "16-event-v3",
  "16-alert-v3",
  "24-qa",
  "16-spotlight-pin",
  "14-spotlight-check",
  "14-close",
  "14-check",
  "14-live-qa-settings",
  "16-workflows-v2-error",
  "16-workflows-v2-members",
  "16-workflows-v2-valid",
  "12-workflows-end",
  "20-community-inbox",
  "20-community-bot",
  "16-stars-ai",
  "36-ai-bot",
  "16-workflows-v2-invalid",
  "16-workflows-v2-community-member-joined-community",
  "16-workflows-v2-community-member-profile-field-updated",
  "16-workflows-v2-gamification-member-leveled-up",
  "16-workflows-v2-community-member-rsvps-to-event",
  "16-workflows-v2-event-ended-for-member",
  "16-workflows-v2-event-starts-in-an-hour",
  "16-workflows-v2-community-member-attended-live-event",
  "16-workflows-v2-community-member-joined-space",
  "16-workflows-v2-community-member-joined-space-group",
  "16-workflows-v2-community-member-leaves-space-group",
  "16-workflows-v2-community-member-subscribed-to-paywall",
  "16-workflows-v2-community-member-unsubscribed-from-paywall",
  "16-workflows-v2-community-member-ended-trial-without-subscription",
  "16-timeline-node-filled",
] as const;
