import { Typography } from "@circle-react-uikit/Typography";
import { FadingTextEffect } from "../FadingTextEffect";
import { TypingEffect } from "../TypingEffect";

interface AiSummaryBodyTextProps {
  animation?: "none" | "typing" | "fade-in";
  onAnimationComplete?: () => void;
  children: string;
}

export const AiSummaryBodyText = ({
  animation = "none",
  onAnimationComplete,
  children,
}: AiSummaryBodyTextProps) => (
  <Typography.BodySm color="text-default dark:text-dark prose prose-sm">
    {animation === "typing" ? (
      <TypingEffect text={children} onComplete={onAnimationComplete} />
    ) : animation === "fade-in" ? (
      <FadingTextEffect text={children} onComplete={onAnimationComplete} />
    ) : (
      <span className="whitespace-pre-wrap">{children}</span>
    )}
  </Typography.BodySm>
);
