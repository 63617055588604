import { useEffect, useState } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { useAiSummaryContext } from "./Context";

interface AiSummaryHeaderProps {
  animate?: boolean;
  isHidden?: boolean;
  children: React.ReactNode;
}

export const AiSummaryHeader = ({
  children,
  animate = false,
  isHidden = false,
}: AiSummaryHeaderProps) => {
  const { isCollapsed } = useAiSummaryContext();
  const [isCompletelyHidden, setIsCompletelyHidden] = useState(isHidden);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (isHidden) {
      timeout = setTimeout(() => {
        setIsCompletelyHidden(true);
      }, 300);
    } else {
      setIsCompletelyHidden(false);
    }
    return () => timeout && clearTimeout(timeout);
  }, [isHidden]);

  if (isCompletelyHidden) {
    return null;
  }

  return (
    <div
      className={classNames(
        "flex w-full items-center gap-1.5 transition-all duration-300",
        {
          "h-0 opacity-0": isHidden,
        },
      )}
    >
      {isCollapsed && (
        <div className="relative flex h-4 w-4 items-center justify-center">
          <Icon
            type="16-thunder-filled"
            size={16}
            className="text-dark absolute !h-4 !w-4 opacity-100 transition-opacity duration-300 group-hover:opacity-0"
          />
          <Icon
            type="20-stardust-gradient"
            size={20}
            className="absolute !h-4 !w-4 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
          />
        </div>
      )}
      {!isCollapsed && (
        <Icon
          type="20-stardust-gradient"
          size={20}
          className={classNames("!h-5 !w-5", {
            "animate-spin-pause-reverse": animate,
          })}
        />
      )}
      <Typography.LabelSm
        weight="semibold"
        color={classNames("text-dark", {
          "group-hover:bg-gradient-to-b group-hover:from-[#516DF1] group-hover:to-[#6B47D2] group-hover:bg-clip-text group-hover:text-transparent":
            isCollapsed,
          "bg-gradient-to-r from-[#6775EE] to-[#AD89DC] bg-clip-text text-transparent":
            !isCollapsed,
        })}
        leading="leading-tight"
      >
        {children}
      </Typography.LabelSm>
    </div>
  );
};
