import classNames from "classnames";
import { concat } from "lodash";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

export interface UserGroupIconProps {
  users?: any[];
  totalCount?: number;
  size?: "lg" | "md" | "sm";
  isLoading?: boolean;
  isFullRounded?: boolean;
  className?: string;
}

export const UserGroupIcon = ({
  users = [],
  totalCount: totalCountProp,
  size = "lg",
  isLoading,
  isFullRounded = false,
  className = "",
}: UserGroupIconProps) => {
  const totalCount = totalCountProp || users.length;

  if (isLoading) {
    return <SkeletonLoader variant="members-avatar-row" />;
  }

  if (!users?.length) {
    return null;
  }
  const getUserKey = (user: any) =>
    user.id ||
    `user-group-image-${user.name.replace(/ /g, "")}-${Math.random()}`;

  return (
    <div
      className={classNames(
        "avatar-group border-tertiary rounded-md",
        {
          "avatar-group--lg": size === "lg",
          "avatar-group--md": size === "md",
          "avatar-group--sm": size === "sm",
        },
        className,
      )}
    >
      {users.map(user => (
        <UserImage
          key={getUserKey(user)}
          src={user.avatar_url}
          name={user.name}
          size="8"
          hasBorder
          isFullRounded={isFullRounded}
        />
      ))}

      {users.length < totalCount && (
        <div
          className={classNames(
            "bg-secondary text-default z-30 flex max-h-[36px] min-w-[36px] items-center justify-center rounded-full border-2 border-inherit p-1 text-xs font-medium",
          )}
        >
          {"+" + concat((totalCount - users.length).toString()).join("")}
        </div>
      )}
    </div>
  );
};
