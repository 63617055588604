import { useEffect } from "react";
import { updateActionTextAttachmentPropertiesPath } from "../../../helpers/urlHelpers";
import { TrixApiWrapper } from "./TrixApiWrapper";
import { handleEvent } from "./helpers/events";

export const useAttachmentOverlay = editorRef => {
  useEffect(() => {
    // To fix this issue: https://www.notion.so/circleco/7-Can-t-edit-an-image-size-all-communities-19eb63704b5d48e0b1423e2162c4fcce
    // No able to identify why exactly this bug happens
    // Inspired by: https://github.com/basecamp/trix/blob/main/src/trix/controllers/composition_controller.coffee#L15
    // This code changes default attachment
    if (!editorRef.current) {
      return;
    }

    const trixApiWrapper = new TrixApiWrapper(editorRef.current.editor);
    const stopAttachmentEditing = event => {
      event.preventDefault();
      const { target } = event;

      const attachment = trixApiWrapper.getClosestAttachment(target);
      const editorOverlay = target.closest(".editor-overlay");
      if (target.classList.contains("editor-overlay") || editorOverlay) {
        trixApiWrapper.editAttachment(attachment);
        return;
      }
      const position = trixApiWrapper.getPosition();
      trixApiWrapper.setSelectedRange([position, position]);
      trixApiWrapper.stopEditingAttachment(attachment);
      editorRef.current.editorController.compositionDidStopEditingAttachment(
        attachment,
      );
    };

    handleEvent("mousedown", {
      onElement: editorRef.current,
      matchingSelector: "[data-trix-attachment]",
      withCallback: stopAttachmentEditing,
    });
  }, [editorRef]);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }
    const trixApiWrapper = new TrixApiWrapper(editorRef.current.editor);
    const handleClick = event => {
      mayBeRemoveAttachment(event);
      mayBeUpdateAlignment(event);
    };

    const mayBeUpdateAlignment = async event => {
      const updateAlignmentElement = event.target.closest(
        '[data-actionType="update-alignment"]',
      );

      if (updateAlignmentElement) {
        event.preventDefault();
        event.stopPropagation();
        const attachment = trixApiWrapper.getClosestAttachment(
          updateAlignmentElement,
        );
        trixApiWrapper.editAttachment(attachment);
        const response = await fetch(
          updateActionTextAttachmentPropertiesPath(
            attachment.getAttribute("sgid"),
          ),
          {
            body: JSON.stringify({
              attachment_property: {
                alignment: updateAlignmentElement.dataset.value,
              },
            }),
            method: "PATCH",
            dataType: "script",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        );

        const data = await response.json();
        attachment.setAttributes({
          content: data.content,
        });
        trixApiWrapper.stopEditingAttachment(attachment);
        trixApiWrapper.editAttachment(attachment);
      }
    };

    const mayBeRemoveAttachment = event => {
      const removeAttachmentElement = event.target.closest(
        '[data-actionType="remove-attachment"]',
      );
      if (removeAttachmentElement) {
        event.preventDefault();
        event.stopPropagation();
        trixApiWrapper.recordUndoEntry("remove attachment");
        const attachment = trixApiWrapper.getClosestAttachment(
          removeAttachmentElement,
        );
        trixApiWrapper.removeAttachmentById(attachment.id);
      }
    };
    editorRef.current.addEventListener("click", handleClick);

    return () => {
      editorRef.current.removeEventListener("click", handleClick);
    };
  }, [editorRef]);
};
