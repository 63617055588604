import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon/IconNames";
import { Typography } from "@circle-react-uikit/Typography";
import type { ActivityScore, ScoreTypes } from "../utils";
import { getScoreIcon } from "../utils";
import { Container } from "./Container";

const scoreStyle: {
  [key in ScoreTypes]: { className: string; icon: IconType };
} = {
  high: {
    className: "border-v2-success text-v2-success bg-v2-success/5",
    icon: getScoreIcon("high"),
  },
  medium: {
    className: "border-v2-warning text-v2-warning bg-v2-warning/5",
    icon: getScoreIcon("medium"),
  },
  low: {
    className: "border-v2-danger text-v2-danger bg-v2-danger/5",
    icon: getScoreIcon("low"),
  },
  disabled: {
    className: "border border-primary text-dark bg-tertiary",
    icon: getScoreIcon("disabled"),
  },
} as const;

export const ScoreChip = ({
  activityScore,
}: {
  activityScore: ActivityScore;
}) => {
  const { className, icon } = scoreStyle[activityScore.activity];

  const contentToRender = (activityScore: ActivityScore) => {
    if (activityScore.score !== undefined) {
      return activityScore.score;
    }
    return activityScore.label;
  };

  return (
    <Container
      className={classNames(
        "inline-flex items-center justify-center gap-x-2 rounded-3xl border px-2.5 py-1.5",
        className,
      )}
    >
      <Icon className="!w-4" type={icon} aria-hidden size={16} />
      <Typography.LabelXs weight="medium" color="text-default">
        {contentToRender(activityScore)}
      </Typography.LabelXs>
    </Container>
  );
};
