import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { isValidTiptapBody } from "@circle-react/components/QuickPostV2/validation";
import { FormTipTapBlockEditor } from "@circle-react-shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { DateTimePicker } from "@circle-react-uikit/DateTimePicker";
import { Form } from "@circle-react-uikit/Form";
import { useScheduledEmailModalStore } from "../store";
import { InsertVariablesMenu } from "./InsertVariablesMenu";

const audienceOptions = [
  {
    label: t("scheduled_emails.form.going"),
    value: "going",
  },
  {
    label: t("scheduled_emails.form.not_going"),
    value: "not_going",
  },
  {
    label: t("scheduled_emails.form.invited"),
    value: "invited",
  },
];

interface ScheduledEmailFormProps {
  maxDate?: Date;
  minDate?: Date;
}

export const ScheduledEmailForm = ({
  maxDate,
  minDate,
}: ScheduledEmailFormProps) => {
  const { setIsDirty } = useScheduledEmailModalStore();
  const {
    formState: { isDirty },
    setError,
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);

  const setBodyError = (isValid: boolean) => {
    if (isValid) {
      clearErrors("email_body");
      return;
    }

    setError("email_body", {
      type: "Invalid input",
      message: t("scheduled_emails.form.email_body_required_error"),
    });
  };
  return (
    <div className="mt-2 flex flex-col gap-6">
      <Form.Item
        hideBorder
        name="scheduled_at"
        hideLabel
        rules={{
          required: t("scheduled_emails.form.reminder_date_required_error"),
        }}
        className="!p-0"
        errorClassName="text-v2-danger"
      >
        <DateTimePicker
          className="flex-col gap-6 md:flex-row"
          classNameDate="w-full"
          classNameTime="w-full"
          labelDate={t("scheduled_emails.form.reminder_date_title")}
          labelTime={t("scheduled_emails.form.reminder_time_title")}
          name="scheduled_at"
          datePlaceholder={t("scheduled_emails.form.reminder_date_placeholder")}
          timePlaceholder={t("scheduled_emails.form.reminder_time_placeholder")}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Form.Item>
      <Form.Item
        name="metadata.send_to"
        label={t("scheduled_emails.form.send_to")}
        labelClassName="text-sm"
        className="!p-0"
        hideDescription
        hideBorder
        errorClassName="text-v2-danger"
      >
        <Form.AnimatedSelect
          name="metadata.send_to"
          fullWidth
          options={audienceOptions}
        />
      </Form.Item>
      <Form.Item
        name="subject"
        label={t("scheduled_emails.form.subject")}
        labelClassName="text-sm"
        className="!p-0"
        hideBorder
        hideDescription
        placeholder={t("scheduled_emails.form.subject_placeholder")}
        rules={{
          required: t("scheduled_emails.form.subject_required_error"),
        }}
        errorClassName="text-v2-danger"
      >
        <Form.Input type="text" />
      </Form.Item>
      <Form.Item
        name="email_body"
        label={t("scheduled_emails.form.message_body")}
        labelClassName="text-sm"
        className="flex h-full grow flex-col !p-0"
        hideBorder
        hideDescription
        errorClassName="text-v2-danger"
      >
        <div className="form-control relative h-full grow py-3">
          <FormTipTapBlockEditor
            className="h-full overflow-y-scroll"
            editorClassName="h-full overflow-y-scroll"
            placeholder={t(`scheduled_emails.form.message_body`)}
            name="email_body[body]"
            disabledExtensions={["mention", "poll", "cta"]}
            disabledSlashMenuCategories={["Embed"]}
            shouldPreviewFilesAsLinks
            shouldShowTextModifiersMenu={false}
            toolbars={[
              {
                portalId: "drip-email-notification-variables-toolbar",
                component: InsertVariablesMenu,
              },
            ]}
            onChangeCallback={value => {
              setBodyError(isValidTiptapBody({ body: value }));
            }}
          />
          <div id="drip-email-notification-variables-toolbar" />
        </div>
      </Form.Item>
    </div>
  );
};
