import type { ReactNode } from "react";

interface NavigationProps {
  children?: ReactNode;
}

export const Navigation = ({ children }: NavigationProps) => (
  <div className="flex w-full items-center gap-6 overflow-auto lg:justify-center lg:overflow-visible">
    {children}
  </div>
);
