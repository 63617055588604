import { VirtualAnchorPopup } from "../../VirtualAnchorPopup";
import { useEditorApi } from "../TrixEditorContext";
import { useEditorMode } from "../useEditorMode";
import { BlockSelectorList } from "./BlockSelectorList";
import { useBlockSelectorOnSelect } from "./useBlockSelectorOnSelect";

export const InlineBlockSelector = () => {
  const { subMode, showInlineBlockSelector, startPositionRect, startPosition } =
    useEditorMode();
  const isVisible = showInlineBlockSelector();

  const { getStringInRange, getPosition } = useEditorApi();

  const onSelect = useBlockSelectorOnSelect().eventHandler;

  if (!isVisible) {
    return null;
  }

  const rect = startPositionRect();
  const cursorPosition = getPosition();
  const prefixLength = subMode === "withPrefix" ? 1 : 0;
  const searchQuery = getStringInRange([
    startPosition + prefixLength,
    cursorPosition,
  ]);

  if (!rect) {
    return null;
  }

  const { top = 0, bottom = 0, left = 0, right = 0 } = rect;
  const anchorRect = {
    top: top - 10,
    bottom: bottom + 10,
    left: left,
    right: right,
  };

  const styles = {
    container: {
      width: 300,
    },
  };

  return (
    <div className="inline-block-selector">
      <div className="trix__dropdown--wrap">
        <VirtualAnchorPopup
          show
          anchorRect={anchorRect}
          placement="bottom-start"
        >
          <div style={styles.container}>
            <BlockSelectorList searchQuery={searchQuery} onSelect={onSelect} />
          </div>
        </VirtualAnchorPopup>
      </div>
    </div>
  );
};
