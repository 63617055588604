import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classNames from "classnames";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography/index";
import { RadioButtonIcon } from "./RadioButtonIcon";

export interface RadioButtonProps extends ComponentPropsWithoutRef<"label"> {
  label: ReactNode;
  name: string;
  id: string;
  isChecked: boolean;
  onChange: (value: any) => void;
  value: any;
  size?: "sm" | "md";
  hasRadioCheckmarks?: boolean;
  icon?: IconType;
  iconSize?: number;
  checkedOptionClassName?: string;
}

export const RadioButton = ({
  label,
  name,
  id,
  isChecked,
  onChange,
  value,
  size,
  hasRadioCheckmarks,
  icon,
  iconSize,
  checkedOptionClassName = "",
  ...rest
}: RadioButtonProps) => {
  const hasIcon = icon && iconSize;

  return (
    <label
      htmlFor={id}
      {...rest}
      className={classNames(
        "m-0 flex w-full items-center justify-between border",
        {
          "bg-selector-active border-secondary text-selector-active drop-shadow":
            isChecked,
          "bg-primary border-selector-inactive text-default": !isChecked,
          "rounded-lg px-3.5 py-2.5": size === "sm",
          "rounded-xl px-5 py-4": size === "md",
          [checkedOptionClassName]: isChecked,
        },
      )}
    >
      <div
        className={classNames("mb-0", {
          "flex-1": !hasIcon,
          "flex items-center gap-3": hasIcon,
        })}
      >
        {hasIcon && (
          <span>
            <Icon
              type={icon}
              size={iconSize}
              className={classNames({
                "!h-4 !w-4": iconSize === 16,
                "!h-5 !w-5": iconSize === 20,
              })}
            />
          </span>
        )}
        <Typography.LabelSm weight="medium">
          <span
            className={classNames({
              "text-selector-active": isChecked,
              "text-default": !isChecked,
            })}
          >
            {label}
          </span>
        </Typography.LabelSm>
      </div>
      <RadioButtonIcon
        isChecked={isChecked}
        hasRadioCheckmarks={hasRadioCheckmarks}
      />
      <input
        className="hidden"
        type="radio"
        name={name}
        id={id}
        onChange={event => onChange(event.target.checked ? value : null)}
      />
    </label>
  );
};
