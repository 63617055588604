import { useEditorApi } from "../TrixEditorContext";
import { useDisableWindowScroll } from "../useDisableWindowScroll";
import { useEditorMode } from "../useEditorMode";
import { EmojiSelectorList } from "./EmojiSelectorList";

export const InlineEmojiSelector = () => {
  const {
    showInlineEmojiSelector,
    startPosition,
    resetEditorMode,
    startPositionRect,
  } = useEditorMode();
  const isVisible = showInlineEmojiSelector();
  const { insertString, setSelectedRange, getStringInRange, getPosition } =
    useEditorApi();
  useDisableWindowScroll(isVisible);

  if (!isVisible) return null;
  const rect = startPositionRect();
  const cursorPosition = getPosition();
  const query = getStringInRange([startPosition + 1, cursorPosition]);

  if (!query || query.length < 1) return null;

  const onEmojiSelect = emoji => {
    if (emoji) {
      const cp = getPosition();
      setSelectedRange([startPosition, cp]);
      insertString(emoji.native);
    }
    resetEditorMode();
  };

  return (
    <div className="inline-emoji-selector">
      <EmojiSelectorList
        rect={rect}
        query={query}
        onEmojiSelect={onEmojiSelect}
      />
    </div>
  );
};
