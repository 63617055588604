import { isElement } from "@circle-react/helpers/htmlContent";

export interface HtmlNode {
  type: "text" | "element";
  content: string;
  tag?: string;
  children?: HtmlNode[];
  wordStart?: number;
  wordCount?: number;
}

export const parseHtmlNodeTree = (
  html: string,
): { tree: HtmlNode[]; totalWords: number } => {
  const div = document.createElement("div");
  div.innerHTML = html;
  let wordPosition = 0;

  const parseNode = (node: ChildNode): HtmlNode | null => {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent || "";
      if (!text) return null;

      const words = text.match(/\S+\s*/g) || [];
      const wordCount = words.length;
      const nodeInfo = {
        type: "text" as const,
        content: text,
        wordStart: wordPosition,
        wordCount,
      };
      wordPosition += wordCount;
      return nodeInfo;
    }

    if (isElement(node)) {
      const startPosition = wordPosition;
      const children = Array.from(node.childNodes)
        .map(parseNode)
        .filter((n): n is HtmlNode => n !== null);

      const wordCount = children.reduce(
        (sum, child) => sum + (child.wordCount || 0),
        0,
      );

      return {
        type: "element",
        tag: node.tagName.toLowerCase(),
        content: node.outerHTML,
        children,
        wordStart: startPosition,
        wordCount,
      };
    }
    return null;
  };

  const tree = Array.from(div.childNodes)
    .map(parseNode)
    .filter((n): n is HtmlNode => n !== null);

  return { tree, totalWords: wordPosition };
};
