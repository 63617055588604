import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useIsActivityScoreEnabled } from "@circle-react/hooks/useIsActivityScoreEnabled";
import type {
  ActivityResponseMap,
  ActivityScoreDetail,
  ActivityScoreResponse,
} from "../utils";

export const useGetActivityScore = ({ ids }: { ids: number[] }) => {
  const isMemberActivityScoreEnabled = useIsActivityScoreEnabled();

  return useQuery({
    queryKey: ["MEMBER_ACTIVITY_SCORE", ids],
    queryFn: () =>
      reactQueryGet(
        internalApi.memberActivityScore.index({
          params: { community_member_ids: ids },
        }),
      ),
    select(data: ActivityScoreResponse[]): ActivityResponseMap {
      return data.reduce(
        (acc, memberScore) =>
          acc.set(memberScore.community_member_id, {
            activityScore: memberScore.activity_score,
            scoreBreakdown: memberScore.score_breakdown,
          }),
        new Map<number, ActivityScoreDetail>(),
      );
    },
    enabled: isMemberActivityScoreEnabled && ids.length > 0,
  });
};
