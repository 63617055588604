import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { isRefundedOrPartialRefunded } from "@circle-react/helpers/communityMemberChargeHelpers";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { CustomerColumn } from "@circle-react-shared/Paywalls/CustomerColumn";
import { Typography } from "@circle-react-uikit/Typography";

const localeStringRoot = "settings.paywalls_admin_dashboard.charges";

export const ChargeInformation = ({ charge }) => {
  const dateTime = new Date(charge.created_at);
  const createdAtString = formattedDateTime({
    dateTime,
    format: "long_date_short_time",
  });

  return (
    <div>
      <div className="border-b-2 pb-4">
        <CustomerColumn
          customer={charge.community_member}
          shouldOpenAdminMemberEdit={false}
        />
      </div>
      <div className="grid grid-cols-1 gap-y-3 py-4">
        <div>
          <div>
            <Typography.LabelSm weight="semibold">
              {t(
                `${localeStringRoot}.charge_details_modal.details_labels.transaction_date`,
              )}
            </Typography.LabelSm>
          </div>
          <Typography.LabelSm>{createdAtString}</Typography.LabelSm>
        </div>

        <div>
          <div>
            <Typography.LabelSm weight="semibold">
              {t(
                `${localeStringRoot}.charge_details_modal.details_labels.amount_paid`,
              )}
            </Typography.LabelSm>
          </div>
          <Typography.LabelSm>
            {charge.display_amount_with_currency}
          </Typography.LabelSm>
        </div>

        {isRefundedOrPartialRefunded(charge) && (
          <div>
            <div>
              <Typography.LabelSm weight="semibold">
                {t(
                  `${localeStringRoot}.charge_details_modal.details_labels.amount_refunded`,
                )}
              </Typography.LabelSm>
            </div>
            <Typography.LabelSm>
              {charge.display_refunded_amount_with_currency}
            </Typography.LabelSm>
          </div>
        )}
      </div>
    </div>
  );
};

ChargeInformation.propTypes = {
  charge: PropTypes.object.isRequired,
};
