import { useEffect, useRef } from "react";
import tippy from "tippy.js";
import "tippy.js/themes/light.css";

/** @deprecated Please use the new tooltip at "@circle-react-shared/TippyV2" */
export const Tippy = ({
  children,
  className,
  dataTestId = "",
  delay = 0,
  arrow = true,
  size = "regular",
  animation = "fade",
  ...tippyProps
}: any) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    ref.current &&
      tippy(ref.current, {
        allowHTML: true,
        delay,
        arrow,
        size,
        animation,
        ...tippyProps,
      });
  }, []);

  return (
    <span className={className} ref={ref} data-testid={dataTestId}>
      {children}
    </span>
  );
};
