import type { ComponentType } from "react";
import { nanoid } from "nanoid";
import type { FieldTypes } from "@circle-react-shared/ProfileFields/constants";
import { FIELD_TYPES } from "@circle-react-shared/ProfileFields/constants";

interface FilterUpgradeDetails {
  minimumRequiredTier: string;
}

/** Configration object used to render the filter on UI */
export interface RenderFilter {
  name: string;
  /** @TODO component prop type to be updated when API is stable. */
  component: ComponentType<any>;
  label: string;
  /** If the list of filters requires a different label, use this key. */
  listLabel?: string;
  upgradeDetails?: FilterUpgradeDetails;
}

export interface RenderProfileFieldFilter extends RenderFilter {
  id: number;
  field_type: FieldTypes;
}

export const isRenderProfileFieldFilter = (
  renderFilterParam: RenderFilter,
): renderFilterParam is RenderProfileFieldFilter =>
  "id" in renderFilterParam && "field_type" in renderFilterParam;

/** Communication with API starts with this shape. Specialize this with types for field type and value  */
export interface BaseFilter {
  id: string;
  key: string;
  value?: unknown;
  /** When a filter is added, automatically open it. */
  isInitialOpen?: boolean;
}
export interface StringValueFilter extends BaseFilter {
  value?: string;
}

export interface ProfileFieldBaseFilter extends BaseFilter {
  key: "profile_field";
  profile_field_id: number;
  profile_field_type: FieldTypes;
}

export const createBaseFilter = <T extends BaseFilter>(
  props: Omit<T, "id">,
) => ({
  id: nanoid(),
  ...props,
});

const isProfileFieldType = (
  profileFieldType: unknown,
): profileFieldType is FieldTypes =>
  Object.values(FIELD_TYPES).includes(profileFieldType as FieldTypes);

export const isProfileFieldBaseFilter = (
  baseFilter: BaseFilter,
): baseFilter is ProfileFieldBaseFilter =>
  baseFilter.key === "profile_field" &&
  "profile_field_id" in baseFilter &&
  "profile_field_type" in baseFilter &&
  isProfileFieldType(baseFilter.profile_field_type);
