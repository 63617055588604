import { Icon } from "@circle-react-shared/Icon";

export interface RadioButtonIconProps {
  isChecked: boolean;
  hasRadioCheckmarks?: boolean;
}

export const RadioButtonIcon = ({
  isChecked = false,
  hasRadioCheckmarks = false,
}: RadioButtonIconProps) => {
  if (hasRadioCheckmarks) {
    return isChecked ? (
      <span className="text-selector-active flex">
        <Icon size={24} type="24-radio-checked" />
      </span>
    ) : (
      <span className="text-lightest flex">
        <Icon size={24} type="24-radio-unchecked" />
      </span>
    );
  }

  if (isChecked) {
    return (
      <span className="text-selector-active flex">
        <Icon size={16} type="16-check-circle" />
      </span>
    );
  }

  return null;
};
