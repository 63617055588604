export const LeftArrow = () => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 5C11.5 5.41421 11.1642 5.75 10.75 5.75L0.75 5.75C0.335787 5.75 2.93554e-08 5.41421 6.55671e-08 5C1.01779e-07 4.58579 0.335787 4.25 0.75 4.25L10.75 4.25C11.1642 4.25 11.5 4.58579 11.5 5Z"
      fill="#1F3349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28033 9.53033C4.98744 9.82322 4.51256 9.82322 4.21967 9.53033L0.21967 5.53033C-0.0732227 5.23744 -0.0732227 4.76256 0.21967 4.46967L4.21967 0.46967C4.51256 0.176777 4.98744 0.176777 5.28033 0.46967C5.57322 0.762563 5.57322 1.23744 5.28033 1.53033L1.81066 5L5.28033 8.46967C5.57322 8.76256 5.57322 9.23744 5.28033 9.53033Z"
      fill="#1F3349"
    />
  </svg>
);
export const RightArrow = () => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 5C0.25 4.58579 0.585786 4.25 1 4.25H11C11.4142 4.25 11.75 4.58579 11.75 5C11.75 5.41421 11.4142 5.75 11 5.75H1C0.585786 5.75 0.25 5.41421 0.25 5Z"
      fill="#1F3349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46967 0.46967C6.76256 0.176777 7.23744 0.176777 7.53033 0.46967L11.5303 4.46967C11.8232 4.76256 11.8232 5.23744 11.5303 5.53033L7.53033 9.53033C7.23744 9.82322 6.76256 9.82322 6.46967 9.53033C6.17678 9.23744 6.17678 8.76256 6.46967 8.46967L9.93934 5L6.46967 1.53033C6.17678 1.23744 6.17678 0.762563 6.46967 0.46967Z"
      fill="#1F3349"
    />
  </svg>
);
export const Close = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.34554 0.654714C9.57567 0.884845 9.57567 1.25796 9.34554 1.48809L1.4884 9.34523C1.25827 9.57536 0.88515 9.57536 0.65502 9.34523C0.424889 9.1151 0.424889 8.74199 0.65502 8.51186L8.51216 0.654714C8.74229 0.424584 9.11541 0.424584 9.34554 0.654714Z"
      fill="#1F3349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.65502 0.654714C0.88515 0.424584 1.25827 0.424584 1.4884 0.654714L9.34554 8.51186C9.57567 8.74199 9.57567 9.1151 9.34554 9.34523C9.11541 9.57536 8.74229 9.57536 8.51216 9.34523L0.65502 1.48809C0.424889 1.25796 0.424889 0.884845 0.65502 0.654714Z"
      fill="#1F3349"
    />
  </svg>
);
