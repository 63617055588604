import Trix from "trix";

export const TrixOverrides = {
  perform: () => {
    Trix.config.blockAttributes.heading2 = {
      tagName: "h2",
      terminal: true,
      breakOnReturn: true,
      group: false,
    };

    Trix.config.blockAttributes.heading3 = {
      tagName: "h3",
      terminal: true,
      breakOnReturn: true,
      group: false,
    };

    Trix.config.blockAttributes.quote = {
      tagName: "blockquote",
      nestable: true,
      terminal: true,
      breakOnReturn: true,
    };
  },
};
