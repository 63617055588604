import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { useEditorMode } from "../useEditorMode";

export const AddLink = () => {
  const { toggleOnInlineLinkForm } = useEditorMode();

  const onClickHandler = () => {
    toggleOnInlineLinkForm();
  };

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="toolbar__btn"
      data-trix-attribute="href"
      data-trix-action="link"
      data-trix-key="k"
      aria-label={t("link")}
    >
      <Icon type="link" size={20} />
    </button>
  );
};
