import PropTypes from "prop-types";
import { SelectRadioGroup } from "@circle-react-uikit/SelectRadioGroup";
import { Typography } from "@circle-react-uikit/Typography";
import { Header } from "../Header";
import { useCustomizeUIData } from "../Provider";

export const DefaultHeaderView = ({
  currentScreenSize = "desktop",
  currentSection = "",
  formId = "",
  onChangeScreenSize,
  onChangeSection,
  onExit,
  onSave,
  sections = [],
  title = "",
  isSaving = false,
  shouldShowActions = true,
  ...rest
}) => {
  const { isSaveButtonDisabled } = useCustomizeUIData();

  const radioOptions = sections.map(value => ({
    value,
    label: value,
  }));

  return (
    <Header {...rest}>
      <Header.Actions className="!gap-4">
        <Header.ExitButton onClick={onExit} />
        {title && (
          <Typography.LabelMd weight="semibold">{title}</Typography.LabelMd>
        )}
      </Header.Actions>
      <Header.Actions>
        <SelectRadioGroup
          value={currentSection}
          onChange={onChangeSection}
          options={radioOptions}
        />
      </Header.Actions>
      {shouldShowActions && (
        <Header.Actions>
          <Header.ScreenSizeSelect
            onSelect={onChangeScreenSize}
            selected={currentScreenSize}
          />
          <Header.SaveButton
            onClick={onSave}
            formId={formId}
            disabled={isSaving || isSaveButtonDisabled}
            variant="circle"
          />
        </Header.Actions>
      )}
    </Header>
  );
};

DefaultHeaderView.propTypes = {
  className: PropTypes.string,
  currentScreenSize: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
  currentSection: PropTypes.string,
  formId: PropTypes.string,
  onChangeScreenSize: PropTypes.func,
  onChangeSection: PropTypes.func,
  onExit: PropTypes.func,
  onSave: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  isSaving: PropTypes.bool,
  shouldShowActions: PropTypes.bool,
};
