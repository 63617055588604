import PropTypes from "prop-types";
import classNames from "classnames";

export const Header = ({ children, className = "", ...rest }) => (
  <header
    className={classNames(
      "bg-primary border-primary grid-in-header h-full w-full overflow-x-auto border-b",
      className,
    )}
    {...rest}
  >
    {children}
  </header>
);

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
