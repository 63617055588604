import { Icon } from "../../Icon";
import { AttachFileForm } from "../AttachFileForm";

export const FileUploader = () => (
  <AttachFileForm>
    <button type="submit" className="toolbar__btn toolbar__btn--file">
      <Icon type="toolbar-file" size={20} />
    </button>
  </AttachFileForm>
);
