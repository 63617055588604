import { useEffect, useState } from "react";
import { useEmojiMartData } from "@circle-react-shared/uikit/EmojiPickerV3/Pickers/EmojiMart/useEmojiMartData";
import { getEmojiMart } from "@circle-react-shared/uikit/TipTapBlockEditor/InlineEmojiExtension/getEmojiMart";
import { InteractiveList } from "../../InteractiveList";

export const EmojiSelectorList = ({ rect, query, onEmojiSelect }) => {
  const [emojis, setEmojis] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEmojiMartData({
    options: {
      onSuccess: async data => {
        const module = await getEmojiMart();
        const { init } = module;
        init({ data });
      },
    },
  });

  useEffect(() => {
    const loadEmojiIndex = async () => {
      const module = await getEmojiMart();
      const { SearchIndex } = module;
      const emojis = await SearchIndex.search(query);
      const results = emojis.map(emoji => ({
        native: emoji.skins[0].native,
        colons: emoji.skins[0].shortcodes,
      }));
      setEmojis(results);
      setIsLoading(false);
    };

    if (rect && query) {
      loadEmojiIndex();
    }
  }, [rect, query]);

  if (!rect || isLoading) {
    return null;
  }

  const { top, left } = rect;
  const styles = {
    container: {
      position: "fixed",
      left,
      top: top + 30,
      zIndex: 1000,
      width: 292,
    },
  };

  return (
    <div style={styles.container}>
      <InteractiveList
        getKey={item => item.colons}
        getItemContent={item => (
          <>
            <span className="emoji-item__native">{item.native}</span>
            <span className="emoji-item__colons">{item.colons}</span>
          </>
        )}
        items={emojis}
        onSelect={onEmojiSelect}
        disableHorizontalArrowKeys
      />
    </div>
  );
};
