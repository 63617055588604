import { useEffect, useState } from "react";

export const useAutoExpandEditor = (editorRef, containerRef) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (!editorRef.current || !containerRef.current) {
      return;
    }

    const mayBeExpand = () => {
      const { children } = editorRef.current;
      let maxScrollWidth = 0;
      for (let i = 0; i < children.length; i++) {
        maxScrollWidth = Math.max(maxScrollWidth, children[i].scrollWidth);
      }
      setExpanded(maxScrollWidth >= containerRef.current.scrollWidth - 255);
    };
    editorRef.current.addEventListener("trix-change", mayBeExpand);
    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener("trix-change", mayBeExpand);
      }
    };
  }, [editorRef, containerRef]);

  return expanded;
};
