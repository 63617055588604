import classNames from "classnames";
import { useScrollIntoView } from "@circle-react/hooks/utils/useScrollIntoView";
import type { Record } from "@circle-react-shared/CommunityMemberPicker/interfaces";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export interface CommunityMemberResultProps {
  record: Record;
  onClick?: (record: Record) => void;
  isSelected: boolean;
}

export const CommunityMemberResult = ({
  record,
  onClick,
  isSelected,
}: CommunityMemberResultProps) => {
  const ref = useScrollIntoView<HTMLButtonElement>(isSelected);
  const { name, avatar_url: avatarUrl } = record;

  const handleClick = () => {
    onClick?.(record);
  };

  return (
    <button
      type="button"
      className={classNames(
        "hover:bg-tertiary flex w-full items-center gap-3 px-5 py-2 text-left first:pt-4 last:pb-4",
        { "bg-tertiary": isSelected },
      )}
      onClick={handleClick}
      ref={ref}
    >
      <UserImage name={name} src={avatarUrl} size="8" />
      <Typography.LabelSm>{name}</Typography.LabelSm>
    </button>
  );
};
