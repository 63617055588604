import * as PropTypes from "prop-types";

export const DisplayNone = props => {
  const { condition, children } = props;
  const style = {};
  if (condition) {
    style.display = "none";
  }
  return <div style={style}>{children}</div>;
};

DisplayNone.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};
