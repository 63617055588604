import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Icon } from "../Icon";

export const CursorPagination = ({
  fetchNewPage,
  footerDistanceFromBottom = -1000,
  componentId,
  onEvent = "scrollDown",
  messageId,
  isLoading,
}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const component = document.getElementById(componentId) || window;

    const scrollHandler = async () => {
      if (isLoading) {
        return;
      }

      let sendRequest = false;
      if (onEvent === "scrollUp") {
        sendRequest = component.scrollTop < 20;
      } else {
        const position = elementRef.current.getBoundingClientRect();
        const windowHeight = component.innerHeight || component.clientHeight;
        const loc = windowHeight - position.top;
        sendRequest = loc > footerDistanceFromBottom;
      }

      if (sendRequest) {
        await fetchNewPage(messageId);
      }
    };

    component.addEventListener("scroll", scrollHandler);
    return () => {
      component.removeEventListener("scroll", scrollHandler);
    };
  }, [messageId, isLoading, componentId]);

  return (
    <div ref={elementRef} className="flex justify-center">
      {isLoading && <Icon type="loader" />}
    </div>
  );
};

CursorPagination.propTypes = {
  fetchNewPage: PropTypes.func,
  debounceInterval: PropTypes.number,
  footerDistanceFromBottom: PropTypes.number,
  componentId: PropTypes.string,
  onEvent: PropTypes.oneOf(["scrollDown", "scrollUp"]),
  messageId: PropTypes.number,
};
