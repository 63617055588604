import { noop } from "lodash";
import { formatPaywallCurrencyAmount } from "@/react/helpers/paywalls/currencies";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { usePaywallRefundCharge } from "./usePaywallRefundCharge";

export const useRefundChargeModal = ({ charge, onSuccess }: any) => {
  const modal = useModal();
  const defaultValues = {
    amount: formatPaywallCurrencyAmount(
      charge.currency,
      charge.amount_to_refund_raw,
      {
        includeCurrencyCode: false,
        forceMaxFractionDigits: true,
      },
    ),
  };

  const callback = {
    refundCharge: {
      onSuccess: () => {
        void modal.hide();
        onSuccess && onSuccess();
      },
      onError: noop,
    },
  };
  const { refundMutation } = usePaywallRefundCharge({
    charge,
    callback,
  });

  const handleSubmit = (data: any) => {
    // Normalizing data
    data.amount = Number(data.amount.replace(/\D/g, ""));
    refundMutation.mutate(data);
  };

  return {
    defaultValues,
    handleSubmit,
    modal,
    isSubmitting: refundMutation.isLoading,
    onClose: () => !refundMutation.isLoading && modal.hide(),
  };
};
