import PropTypes from "prop-types";
import fuzzysort from "fuzzysort";
import { sortBy, uniq } from "lodash";
import { useTrixEditorFeatureFlagContext } from "@circle-react-shared/TrixEditor/TrixEditorFeatureFlagContext";
import { InteractiveList } from "../../InteractiveList";
import { ACTIONS } from "./ACTIONS";

const getAllEnabledActions = featureFlags =>
  ACTIONS.filter(action => {
    if (action.type === "embed") {
      return featureFlags.embeds;
    }
    if (action.type === "attachment_video") {
      return featureFlags.videoUpload;
    }
    if (action.type === "mention") {
      return featureFlags.mentions;
    }
    if (
      action.type !== "attachment_image" &&
      action.subsection?.toLowerCase() === "upload"
    ) {
      return featureFlags.fileUploadLink;
    }
    return true;
  });
export const BlockSelectorList = ({ onSelect, searchQuery = "" }) => {
  const featureFlags = useTrixEditorFeatureFlagContext();

  searchQuery = searchQuery || "";
  let blockSelectors = getAllEnabledActions(featureFlags);
  if (searchQuery.length) {
    blockSelectors = fuzzysort
      .go(searchQuery, blockSelectors, {
        keys: ["title", "subtitle", "subsection"],
      })
      .map(res => res.obj);
  }

  const orderedSubSections = uniq(
    blockSelectors.map(action => action.subsection),
  );
  const items = sortBy(blockSelectors, item =>
    orderedSubSections.indexOf(item.subsection),
  );

  // TODO: Improve top bottom logic for inline block selector based on anchor location
  return (
    <InteractiveList
      getKey={action => action.type}
      getItemContent={action => <BlockSelectorItemContent action={action} />}
      subsectionKey="subsection"
      items={items}
      onSelect={onSelect}
      disableHorizontalArrowKeys
      loading={false}
    />
  );
};
const BlockSelectorItemContent = ({ action: { icon, title, subtitle } }) => (
  <div>
    <div className="trix__dropdown--preview">
      <span>{icon}</span>
    </div>
    <div className="trix__dropdown--textbox">
      <div className="trix__dropdown--title">{title}</div>
      <div className="trix__dropdown--subtitle">{subtitle}</div>
    </div>
  </div>
);

BlockSelectorItemContent.propTypes = {
  action: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }),
};
BlockSelectorList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
};
