import { useCustomizeUIData } from "../Provider";
import { DefaultHeaderView } from "./DefaultHeaderView";

const DefaultHeader = ({ ...props }) => {
  const {
    screenSize,
    setScreenSize,
    sections,
    currentSection,
    setCurrentSection,
    onExit,
    onSave,
    formId,
    title,
    isSaving,
  } = useCustomizeUIData();

  return (
    <DefaultHeaderView
      {...props}
      title={title}
      currentScreenSize={screenSize}
      onChangeScreenSize={setScreenSize}
      currentSection={currentSection}
      onChangeSection={setCurrentSection}
      sections={sections}
      onExit={onExit}
      onSave={onSave}
      formId={formId}
      isSaving={isSaving}
    />
  );
};

export { DefaultHeader, DefaultHeaderView };
