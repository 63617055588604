import PropTypes from "prop-types";
import classNames from "classnames";

export const RecordTypeLabel = ({ children, className }) => (
  <div
    className={classNames(
      "text-default text-xxs w-full px-5 py-2 font-semibold uppercase",
      className,
    )}
  >
    {children}
  </div>
);

RecordTypeLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
