import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export const LinkButton = ({ label, to }) => (
  <NavLink
    to={to}
    className="text-default hover:text-darkest focus:text-darkest flex items-center rounded-md bg-transparent px-3 py-2 text-sm font-medium leading-5 outline-none"
    activeClassName="bg-secondary !text-darkest font-semibold"
  >
    {label}
  </NavLink>
);

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.object.isRequired,
};
