import { t } from "@/i18n-js/instance";
import {
  Airtable,
  Audio,
  Blockquote,
  Code,
  Codepen,
  Divider,
  Embed,
  Emoji,
  Facebook,
  File,
  Github,
  Googlemaps,
  HOne,
  Image,
  Instagram,
  List,
  Loom,
  Mention,
  NumList,
  Pdf,
  Simplecast,
  Soundcloud,
  Spotify,
  Twitter,
  Typeform,
  Video,
  Videoask,
  Vimeo,
  Wistia,
  Youtube,
} from "./SelectorIcons";

export const ACTIONS = [
  {
    title: t("trix_editor.actions.h2"),
    subtitle: t("trix_editor.actions.medium_section_heading"),
    type: "heading2",
    icon: <HOne />,
    subsection: "Basic",
  },
  {
    title: t("trix_editor.actions.h3"),
    subtitle: t("trix_editor.actions.small_section_heading"),
    type: "heading3",
    icon: <HOne />,
    subsection: "Basic",
  },
  {
    title: t("trix_editor.actions.bulleted_list"),
    subtitle: t("trix_editor.actions.create_a_simple_bullet_list"),
    type: "bullet",
    icon: <List />,
    subsection: "Basic",
  },
  {
    title: t("trix_editor.actions.numbered_list"),
    subtitle: t("trix_editor.actions.create_a_numbered_list"),
    type: "number",
    icon: <NumList />,
    subsection: "Basic",
  },
  {
    title: t("trix_editor.actions.blockquote"),
    subtitle: t("trix_editor.actions.indent_a_quote"),
    type: "quote",
    icon: <Blockquote />,
    subsection: "Basic",
  },
  {
    title: t("trix_editor.actions.divider"),
    subtitle: t("trix_editor.actions.add_a_visual_divider"),
    type: "divider",
    icon: <Divider />,
    subsection: "Basic",
  },
  {
    title: t("trix_editor.actions.code"),
    subtitle: t("trix_editor.actions.add_a_block_of_code"),
    type: "code",
    icon: <Code />,
    subsection: "Basic",
  },
  {
    title: t("trix_editor.actions.mention"),
    subtitle: t("trix_editor.actions.tag_someone_in_this_post"),
    type: "mention",
    icon: <Mention />,
    subsection: "Inline",
  },
  {
    title: t("trix_editor.actions.emoji"),
    subtitle: t("trix_editor.actions.place_an_emoji_in_text"),
    type: "emoji",
    icon: <Emoji />,
    subsection: "Inline",
  },
  {
    title: t("trix_editor.actions.image"),
    subtitle: t("trix_editor.actions.upload_or_embed_image"),
    type: "attachment_image",
    subtype: "image",
    icon: <Image />,
    subsection: "Upload",
  },
  {
    title: t("trix_editor.actions.pdf"),
    subtitle: t("trix_editor.actions.upload_a_pdf_file"),
    type: "attachment_pdf",
    subtype: "pdf",
    icon: <Pdf />,
    subsection: "Upload",
  },
  {
    title: t("trix_editor.actions.video"),
    subtitle: t("trix_editor.actions.upload_or_embed_video"),
    type: "attachment_video",
    subtype: "video",
    icon: <Video />,
    subsection: "Upload",
  },
  {
    title: t("trix_editor.actions.audio"),
    subtitle: t("trix_editor.actions.attach_an_audio_file"),
    type: "attachment_audio",
    subtype: "audio",
    icon: <Audio />,
    subsection: "Upload",
  },
  {
    title: t("trix_editor.actions.file"),
    subtitle: t("trix_editor.actions.attach_a_file"),
    type: "attachment_file",
    subtype: "file",
    icon: <File />,
    subsection: "Upload",
  },
  {
    title: t("trix_editor.actions.embed"),
    subtitle: t("trix_editor.actions.for_embedding_youtube_videos"),
    type: "embed",
    subtype: "embed",
    icon: <Embed />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.youtube"),
    subtitle: t("trix_editor.actions.embed_a_video_from_youtube"),
    type: "embed",
    subtype: "youtube",
    icon: <Youtube />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.wistia"),
    subtitle: t("trix_editor.actions.embed_a_video_from_wistia"),
    type: "embed",
    subtype: "wistia",
    icon: <Wistia />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.vimeo"),
    subtitle: t("trix_editor.actions.embed_a_video_from_vimeo"),
    type: "embed",
    subtype: "vimeo",
    icon: <Vimeo />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.loom"),
    subtitle: t("trix_editor.actions.embed_a_loom_recording"),
    type: "embed",
    subtype: "loom",
    icon: <Loom />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.soundcloud"),
    subtitle: t("trix_editor.actions.embed_a_soundcloud_link"),
    type: "embed",
    subtype: "soundcloud",
    icon: <Soundcloud />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.spotify"),
    subtitle: t("trix_editor.actions.embed_a_spotify_link"),
    type: "embed",
    subtype: "spotify",
    icon: <Spotify />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.simplecast"),
    subtitle: t("trix_editor.actions.embed_a_simplecast_link"),
    type: "embed",
    subtype: "simplecast",
    icon: <Simplecast />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.instagram"),
    subtitle: t("trix_editor.actions.embed_an_instagram_post"),
    type: "embed",
    subtype: "instagram",
    icon: <Instagram />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.videoask"),
    subtitle: t("trix_editor.actions.embed_a_video_from_videoask"),
    type: "embed",
    subtype: "videoask",
    icon: <Videoask />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.facebook"),
    subtitle: t("trix_editor.actions.embed_a_facebook_post"),
    type: "embed",
    subtype: "facebook",
    icon: <Facebook />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.twitter"),
    subtitle: t("trix_editor.actions.embed_a_tweet"),
    type: "embed",
    subtype: "twitter",
    icon: <Twitter />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.typeform"),
    subtitle: t("trix_editor.actions.embed_a_typeform"),
    type: "embed",
    subtype: "typeform",
    icon: <Typeform />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.airtable"),
    subtitle: t("trix_editor.actions.embed_an_airtable"),
    type: "embed",
    subtype: "airtable",
    icon: <Airtable />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.github"),
    subtitle: t("trix_editor.actions.embed_a_gist"),
    type: "embed",
    subtype: "github",
    icon: <Github />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.google_maps"),
    subtitle: t("trix_editor.actions.embed_a_google_map"),
    type: "embed",
    subtype: "googlemaps",
    icon: <Googlemaps />,
    subsection: "Embed",
  },
  {
    title: t("trix_editor.actions.codepen"),
    subtitle: t("trix_editor.actions.embed_a_codepen"),
    type: "embed",
    subtype: "codepen",
    icon: <Codepen />,
    subsection: "Embed",
  },
];
