import type { RefObject } from "react";

export interface TrixEditorRefObject {
  editorRef: RefObject<HTMLElement>;
  focus: () => void;
  insertMention: (mention: string) => void;
  shouldInsert: () => boolean;
  removeMentions: () => void;
}

export const isTrixEditorRefObject = (
  current: unknown,
): current is TrixEditorRefObject =>
  typeof current === "object" &&
  current !== null &&
  "editorRef" in current &&
  "focus" in current &&
  typeof current.focus === "function" &&
  "insertMention" in current &&
  typeof current.insertMention === "function" &&
  "shouldInsert" in current &&
  typeof current.shouldInsert === "function" &&
  "removeMentions" in current &&
  typeof current.removeMentions === "function";
