import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface AiSummaryFooterNoteProps {
  children: React.ReactNode;
}

export const AiSummaryFooterNote = ({ children }: AiSummaryFooterNoteProps) => (
  <div className="flex items-center gap-1">
    <Icon type="16-ai" size={16} className="text-lightest !h-4 !w-4" />
    <Typography.BodyXs color="text-light">{children}</Typography.BodyXs>
  </div>
);
