import { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { get, isEqual, isFunction } from "lodash";
import { useFormContext } from "react-hook-form";
import { usePunditUserContext } from "@circle-react/contexts";
import { useReducerWithThunk } from "@circle-react/hooks/useReducerWithThunk";
import {
  add,
  fetchInitialRecords,
  remove,
  reset,
} from "../CommunityMemberPickerContext/selectionActions";
import { VARIANTS } from "../index";
import { initialState, selectionReducer } from "./selectionReducer";

const CommunityMemberPickerContext = createContext({});
CommunityMemberPickerContext.displayName = "CommunityMemberPickerContext";
export const useCommunityMemberPickerContext = () =>
  useContext(CommunityMemberPickerContext);

export const CommunityMemberPickerProvider = ({
  onChange,
  value,
  name,
  includeAllResultTypes = false,
  excludedMemberIds = [],
  allowCurrentCommunityMember = false,
  children,
  variant = VARIANTS.SIMPLE,
  onModalSave,
}) => {
  const { currentCommunityMember } = usePunditUserContext() || {};
  const [selectionState, dispatch] = useReducerWithThunk(
    selectionReducer,
    initialState(value || [], includeAllResultTypes),
  );

  const { getValues } = useFormContext();

  const excludeCommunityMemberIds = [
    allowCurrentCommunityMember ? undefined : currentCommunityMember?.id,
    ...excludedMemberIds,
    ...selectionState.communityMembers.map(
      communityMember => communityMember.id,
    ),
  ];

  useEffect(
    () => dispatch(fetchInitialRecords(value, includeAllResultTypes)),
    [],
  );

  // Mechanism for resetting the value by setting null from the form
  useEffect(() => {
    if (!value) {
      dispatch(reset(includeAllResultTypes));
    }
  }, [dispatch, includeAllResultTypes, value]);

  useEffect(() => {
    const currentValue = get(getValues(), name, null);
    if (
      variant === VARIANTS.SIMPLE &&
      !isEqual(selectionState.value, currentValue)
    ) {
      onChange(selectionState.value);
    }
  }, [selectionState.value, variant]);

  const onAdd = record => dispatch(add(record, includeAllResultTypes, true));
  const onRemove = record => {
    dispatch(remove(record, includeAllResultTypes));
  };
  const onSave = () => {
    if (!isEqual(selectionState.value, value)) {
      onChange(selectionState.value);
    }
    if (isFunction(onModalSave)) {
      onModalSave(selectionState.value);
    }
  };

  const contextValue = {
    selectionState,
    onAdd,
    onRemove,
    onSave,
    excludeCommunityMemberIds,
    includeAllResultTypes,
  };

  return (
    <CommunityMemberPickerContext.Provider value={contextValue}>
      {children}
    </CommunityMemberPickerContext.Provider>
  );
};

CommunityMemberPickerProvider.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  includeAllResultTypes: PropTypes.bool,
  excludedMemberIds: PropTypes.array,
  allowCurrentCommunityMember: PropTypes.array,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  onModalSave: PropTypes.func,
};
