import type { MouseEvent } from "react";
import { useThemeContext } from "@/react/providers";
import type { Space } from "@circle-react/types";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";

export interface SpaceListProps {
  spaces: Space[];
  handleSpace: (event: MouseEvent<HTMLButtonElement>, id: number) => void;
}

export const SpaceList = ({ spaces, handleSpace }: SpaceListProps) => {
  const { currentAppearance } = useThemeContext();

  return (
    <ul className="react-space-selector-dropdown__list">
      {spaces.map(space => (
        <li key={space.id}>
          <button
            type="button"
            className="w-full text-left"
            onClick={e => handleSpace(e, space.id)}
          >
            <EmojiRenderer
              className="react-space-selector-dropdown__icon"
              emoji={space.emoji ?? "⬜️"}
              appearance={currentAppearance}
              customEmojiUrl={space.custom_emoji_url}
              customEmojiDarkUrl={space.custom_emoji_dark_url}
            />
            <span className="react-space-selector-dropdown__name">
              {space.name}
            </span>
          </button>
        </li>
      ))}
    </ul>
  );
};
