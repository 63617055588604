import { VirtualAnchorPopup } from "../../VirtualAnchorPopup";
import { useEditorApi } from "../TrixEditorContext";
import { useEditorMode } from "../useEditorMode";
import { INLINE_TOOLBAR_ACTIONS } from "./ACTIONS";
import { ToolbarAction } from "./ToolbarAction";

export const InlineToolbar = () => {
  const {
    showInlineToolbar,
    toggleOnInlineLinkForm,
    showInlineLinkForm,
    startPositionRect,
    endPositionRect,
  } = useEditorMode();

  const { trixApiWrapper } = useEditorApi();

  const isVisible = showInlineToolbar() && !showInlineLinkForm();

  if (!isVisible) {
    return null;
  }

  const toggleAttribute = (attribute: any, options: any) => {
    if (trixApiWrapper.attributeIsActive(attribute)) {
      trixApiWrapper.recordUndoEntry(`Unmarking as ${attribute}`);
      trixApiWrapper.deactivateAttribute(attribute);
    } else {
      if (attribute === "href") {
        toggleOnInlineLinkForm();
      } else {
        trixApiWrapper.recordUndoEntry(`Marking as ${attribute}`);
        trixApiWrapper.activateAttribute(attribute, options);
      }
    }
  };

  const { top, left } = startPositionRect() || {};
  const { bottom, right } = endPositionRect() || {};

  return (
    <VirtualAnchorPopup show anchorRect={{ top, bottom, left, right }}>
      <div className="inline-toolbar">
        {INLINE_TOOLBAR_ACTIONS.map(action => (
          <ToolbarAction
            key={action.attribute}
            action={action}
            onClick={toggleAttribute}
            active={trixApiWrapper.attributeIsActive(action.attribute)}
          />
        ))}
      </div>
    </VirtualAnchorPopup>
  );
};
