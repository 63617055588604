import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useState } from "react";
import { isFunction, noop } from "lodash";

type AnyFunction = (...args: any) => any;

export interface CustomizeUIContext {
  addBeforeExit: (func: AnyFunction) => void;
  addBeforeSave: (func: AnyFunction) => void;
  currentSection: string;
  formId: string;
  isCustomizeUI: boolean;
  isSaveButtonDisabled: boolean;
  isSaving: boolean;
  onExit: AnyFunction;
  onSave: AnyFunction;
  removeBeforeExit: () => void;
  removeBeforeSave: () => void;
  screenSize: "desktop" | "tablet" | "mobile";
  sections: string[];
  setCurrentSection: (section: string) => void;
  setIsSaveButtonDisabled: (isDisabled: boolean) => void;
  setScreenSize: (screenSize: "desktop" | "tablet" | "mobile") => void;
  title: string;
}

const CustomizeUIData = createContext<CustomizeUIContext>({
  addBeforeExit: noop,
  addBeforeSave: noop,
  currentSection: "",
  formId: "",
  isCustomizeUI: false,
  isSaveButtonDisabled: false,
  isSaving: false,
  onExit: noop,
  onSave: noop,
  removeBeforeExit: noop,
  removeBeforeSave: noop,
  screenSize: "desktop",
  sections: [],
  setCurrentSection: noop,
  setIsSaveButtonDisabled: noop,
  setScreenSize: noop,
  title: "",
});
CustomizeUIData.displayName = "CustomizeUIData";

export const useCustomizeUIData = () => useContext(CustomizeUIData);

export interface CustomizeUIDataProviderProps {
  children: ReactNode;
  formId?: string;
  initialScreenSize?: "desktop" | "tablet" | "mobile";
  initialSection?: string;
  isSaving?: boolean;
  onExit?: (event: any) => void;
  onSave?: (event: any) => void;
  sections?: string[];
  title?: string;
}

export const CustomizeUIDataProvider = ({
  children,
  formId = "",
  initialScreenSize = "desktop",
  initialSection = "",
  isSaving = false,
  onExit = noop,
  onSave = noop,
  sections = [],
  title = "",
}: CustomizeUIDataProviderProps) => {
  const [screenSize, setScreenSize] = useState(initialScreenSize);
  const [beforeExit, setBeforeExit] = useState<AnyFunction | null>(null);
  const [beforeSave, setBeforeSave] = useState<AnyFunction | null>(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const addBeforeExit = (func: AnyFunction) => setBeforeExit(() => func);
  const addBeforeSave = (func: AnyFunction) => setBeforeSave(() => func);
  const removeBeforeExit = () => setBeforeExit(() => null);
  const removeBeforeSave = () => setBeforeSave(() => null);
  const [currentSection, setCurrentSection] = useState(
    initialSection || sections[0] || "",
  );

  const handleExit = useCallback(
    async event => {
      if (isFunction(beforeExit)) {
        const result = await beforeExit();
        if (!result) return;
      }

      onExit(event);
    },
    [beforeExit, onExit],
  );

  const handleSave = useCallback(
    async event => {
      if (isFunction(beforeSave)) {
        const result = await beforeSave();
        if (!result) return;
      }

      onSave(event);
    },
    [beforeSave, onSave],
  );

  const value: CustomizeUIContext = {
    addBeforeExit,
    addBeforeSave,
    currentSection,
    formId,
    isCustomizeUI: true,
    isSaveButtonDisabled,
    isSaving,
    onExit: handleExit,
    onSave: handleSave,
    removeBeforeExit,
    removeBeforeSave,
    screenSize,
    sections,
    setCurrentSection,
    setIsSaveButtonDisabled,
    setScreenSize,
    title,
  };

  return (
    <CustomizeUIData.Provider value={value}>
      {children}
    </CustomizeUIData.Provider>
  );
};
