import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

export const useGeneralSearch = (query, { enabled = true } = {}) => {
  const { data } = useQuery(
    internalApi.search.communities.index({
      params: {
        query,
        exclude_posts: true,
        exclude_comments: true,
      },
    }),
    {
      enabled: enabled && !!query?.length,
    },
  );

  return {
    records: data?.search_results || [],
  };
};
