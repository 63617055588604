import { DirectUpload } from "@rails/activestorage";
import Trix from "trix";
import {
  actionTextAttachmentsPath,
  activeStorageBlobUrl,
  internalApi,
} from "../../../../helpers/urlHelpers";
import progressBarHtml from "./progressBarHtml";

// this is based on @rails/actiontext/app/javascript/actiontext/attachment_upload

// TODO :: Create a solution that does not need to
export class AttachmentUpload {
  constructor(attachment, editorElement, afterCompleteCallback) {
    this.file = attachment.file;
    this.editor = editorElement.editor;
    this.editorElement = editorElement;
    this.afterCompleteCallback = afterCompleteCallback;
    this.attachment = attachment;
    this.attachment.setAttributes({
      content: this.progressContent(),
      contentType: "custom-progress",
    });
    this.directUpload = new DirectUpload(
      this.file,
      internalApi.actionTextDirectUpload.create({
        params: { new_editor: true },
      }),
      this,
    );
  }

  start() {
    this.directUpload.create(this.directUploadDidComplete.bind(this));
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", event => {
      const progress = (event.loaded / event.total) * 100;
      this.attachment.setAttributes({
        content: this.progressContent(progress),
      });
    });
  }

  async directUploadDidComplete(error, attributes) {
    if (error) {
      this.afterCompleteCallback && this.afterCompleteCallback();
      throw new Error(`Direct upload failed: ${error}`);
    }
    const content = await this.getAttachmentContent(attributes.attachable_sgid);

    this.attachment.setAttributes({
      ...attributes,
      sgid: attributes.attachable_sgid,
      url: this.createBlobUrl(attributes.signed_id, attributes.filename),
      content,
      contentType: attributes.content_type,
    });

    this.afterCompleteCallback && this.afterCompleteCallback();
  }

  async getAttachmentContent(sgid) {
    const response = await fetch(actionTextAttachmentsPath(sgid));
    return await response.text();
  }
  createBlobUrl(signedId, filename) {
    return activeStorageBlobUrl({ signed_id: signedId, filename });
  }

  progressContent(progress = 0.0) {
    return progressBarHtml(parseInt(progress), this.file.name);
  }
}

AttachmentUpload.createFromFile = (
  file,
  editorElement,
  afterCompleteCallback,
) => {
  const attachment = Trix.Attachment.attachmentForFile(file);
  return new AttachmentUpload(attachment, editorElement, afterCompleteCallback);
};
