import { useEffect, useState } from "react";

export const usePreventAccidentalPageLeaveIfTrixDirty = (
  value,
  getFormElement,
) => {
  const [originalValue, setOriginalValue] = useState(value);
  const dirty = originalValue !== value;

  useEffect(() => {
    setOriginalValue(value);
  }, []);

  useEffect(() => {
    const formElement = getFormElement();
    const message = "Are you sure you want to leave?";
    const beforeUploadHandler = event => {
      if (dirty) {
        event.preventDefault();
        event.returnValue = message;
      }
    };
    const beforePageChangeHandler = event => {
      if (dirty && !confirm(message)) {
        event.preventDefault();
      }
    };
    const resetDirty = () => {
      setOriginalValue(value);
    };

    if (formElement) {
      formElement.addEventListener("submit", resetDirty);
    }

    window.addEventListener("beforeunload", beforeUploadHandler);
    document.addEventListener(
      "turbolinks:before-visit",
      beforePageChangeHandler,
    );
    return () => {
      if (formElement) {
        formElement.removeEventListener("submit", resetDirty);
      }
      window.removeEventListener("beforeunload", beforeUploadHandler);
      document.removeEventListener(
        "turbolinks:before-visit",
        beforePageChangeHandler,
      );
    };
  }, [dirty, value, setOriginalValue, getFormElement]);

  return {
    dirty,
  };
};
