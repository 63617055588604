import { createContext, useContext } from "react";
import type { ReactNode } from "react";

export interface AiSummaryContextProps {
  isCollapsed?: boolean;
  onCollapseClick?: () => void;
}

const AiSummaryContext = createContext<AiSummaryContextProps | null>(null);
AiSummaryContext.displayName = "AiSummaryContext";

export const useAiSummaryContext = (): AiSummaryContextProps => {
  const context = useContext(AiSummaryContext);

  if (!context) {
    throw new Error("useAiSummaryContext must be used within a AiSummary");
  }

  return context;
};

interface AiSummaryProviderProps extends AiSummaryContextProps {
  children: ReactNode;
}

export const AiSummaryProvider = ({
  isCollapsed = false,
  onCollapseClick,
  children,
}: AiSummaryProviderProps) => (
  <AiSummaryContext.Provider value={{ isCollapsed, onCollapseClick }}>
    {children}
  </AiSummaryContext.Provider>
);

export { AiSummaryContext };
