import { find } from "lodash";
import { useEditorApi } from "../TrixEditorContext";
import { useEditorMode } from "../useEditorMode";
import { ACTIONS } from "./ACTIONS";

export const useBlockSelectorOnSelect = () => {
  const {
    startPosition,
    resetEditorMode,
    showInlineBlockSelector,
    setModeInlineModalSelector,
    setSubTypeInlineModalSelector,
    triggerInlineEmojiSelector,
  } = useEditorMode();

  const {
    setSelectedRange,
    deleteInDirection,
    recordUndoEntry,
    getPosition,
    insertString,
    insertHr,
    activateAttribute,
    insertLineBreak,
  } = useEditorApi();

  const eventHandler = async ({ type: attribute, subtype }, event) => {
    event.preventDefault();
    event.stopPropagation();
    const cursorPosition = getPosition();
    setSelectedRange([startPosition, cursorPosition]);
    // Dont delete for modal block selector(mobile)
    // Dont delete if start and cursor position are same(happens on desktop when using toolbar lightning button)
    if (showInlineBlockSelector() && startPosition !== cursorPosition) {
      deleteInDirection("forward");
    }

    recordUndoEntry(`Trigger ${attribute}`);
    await resetEditorMode();
    const action = find(ACTIONS, a => a.type === attribute);
    if (action.subsection.toLowerCase() === "upload" || attribute === "embed") {
      setModeInlineModalSelector(attribute);
      setSubTypeInlineModalSelector(subtype);
      return;
    }
    switch (attribute) {
      case "mention": {
        insertString("@");
        break;
      }
      case "emoji": {
        triggerInlineEmojiSelector();
        break;
      }
      case "divider": {
        insertHr();
        insertLineBreak();
        break;
      }
      default:
        activateAttribute(attribute);
    }
  };
  return {
    eventHandler,
  };
};
