import { t } from "@/i18n-js/instance";
import { Modal } from "@circle-react-uikit/Modal";
import { useEditorMode } from "../useEditorMode";
import { BlockSelectorList } from "./BlockSelectorList";
import { useBlockSelectorOnSelect } from "./useBlockSelectorOnSelect";

export const ModalBlockSelector = () => {
  const { showModalBlockSelector, resetEditorMode } = useEditorMode();
  const onSelect = useBlockSelectorOnSelect().eventHandler;

  const isVisible = showModalBlockSelector();

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      variant="large"
      title={t("power_actions")}
      show={isVisible}
      onClose={resetEditorMode}
      className="fullpage-modal__block-selector"
    >
      <BlockSelectorList onSelect={onSelect} />
    </Modal>
  );
};
