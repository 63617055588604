import * as PropTypes from "prop-types";
import { Icon } from "../../shared/Icon";
import { If } from "../If";

export const ModalHeader = ({ onClose, children }) => {
  const _onClose = event => {
    event.preventDefault();
    onClose(event);
  };
  return (
    <div className="modal__header">
      {children}
      <If condition={!!onClose}>
        <a href="#close" onClick={_onClose} className="modal__close">
          <Icon type="close" />
        </a>
      </If>
    </div>
  );
};

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
};
