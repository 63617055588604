import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import isFunction from "lodash/isFunction";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Modal as ModalV2 } from "@circle-react-uikit/ModalV2";
import type {
  ModalAnimationType,
  ModalPositionType,
  ModalSizeType,
} from "@circle-react-uikit/ModalV2/ModalContent";
import { ModalCloseButton } from "./ModalCloseButton";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  contentPortalId?: string;
  children?: ReactNode;
  shouldShowCloseButton?: boolean;
  className?: string;
  animations?: ModalAnimationType;
  size?: ModalSizeType;
  position?: ModalPositionType;
  shouldCloseOnOutsideClick?: boolean;
}

export const Modal = ({
  isOpen,
  onClose,
  contentPortalId,
  children,
  shouldShowCloseButton,
  className = "",
  animations = "slideBottomUp",
  size = "screen-with-header",
  position = "default",
  shouldCloseOnOutsideClick = false,
}: ModalProps) => {
  const isSmScreen = useSmScreenMediaQuery();
  // This snippet triggers the animation when this modal
  // is rendered with isModalOpen === true
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen, setIsModalOpen]);

  return (
    <ModalV2
      isOpen={isModalOpen}
      onClose={() => isFunction(onClose) && onClose()}
      contentPortalId={contentPortalId}
      shouldShowCloseButton={shouldShowCloseButton}
    >
      <ModalV2.Overlay />
      {shouldShowCloseButton && !isSmScreen && (
        <ModalCloseButton onClick={() => isFunction(onClose) && onClose()} />
      )}
      <ModalV2.Content
        size={size}
        animations={animations}
        className={className}
        position={position}
        isOpen={isOpen}
        shouldCloseOnOutsideClick={shouldCloseOnOutsideClick}
      >
        {children}
      </ModalV2.Content>
    </ModalV2>
  );
};
