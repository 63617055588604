import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

export interface ToggleGiphyBtnProps {
  onClick: () => void;
}

export const ToggleGiphyBtn = ({ onClick }: ToggleGiphyBtnProps) => (
  <button
    type="button"
    onClick={onClick}
    className="toolbar__btn toolbar__gif"
    aria-label={t("trix_editor.toolbar.gifs")}
  >
    <Icon type="toolbar-gif" size={20} />
  </button>
);
