import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";
import type { ActivityCardScoreTypes } from "../utils";
import { LabelTitle } from "./LabelTitle";

const scoreStyle: {
  [key in ActivityCardScoreTypes]: { textColor: string; barBg: string };
} = {
  high: {
    textColor: "text-v2-success",
    barBg: "bg-v2-success",
  },
  medium: {
    textColor: "text-v2-warning",
    barBg: "bg-v2-warning",
  },
  low: {
    textColor: "text-v2-danger",
    barBg: "bg-v2-danger",
  },
};

export const SimpleBar = ({
  label,
  score,
  scoreType,
}: {
  label: string;
  score: number;
  scoreType: ActivityCardScoreTypes;
}) => {
  const { textColor, barBg } = scoreStyle[scoreType];

  return (
    <div className="flex flex-col gap-y-3" data-testid={label}>
      <div className="flex flex-row items-center justify-between">
        <LabelTitle>{label}</LabelTitle>
        <Typography.BodyXs weight="font-semibold" color={textColor}>
          {score}
        </Typography.BodyXs>
      </div>
      <div
        className={classNames(
          "h-1 w-full rounded-full bg-gray-200 dark:bg-gray-600",
        )}
      >
        <div
          className={classNames("h-1 rounded-full", barBg)}
          style={{ width: `${Math.min(10, score) * 10}%` }}
        />
      </div>
    </div>
  );
};
