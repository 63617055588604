import Tippy from "@tippyjs/react";

export const VirtualAnchorPopup = ({ show, children, anchorRect, ...rest }) => {
  const { left = 0, right = 0, top = 0, bottom = 0 } = anchorRect || {};

  const styles = {
    toolbar: {
      position: "fixed",
      ...{ left, right, top, bottom },
      width: right - left,
      height: bottom - top,
      zIndex: -1,
    },
  };

  return (
    <Tippy
      interactive
      animation="false"
      duration={0}
      arrow
      delay={0}
      theme="light-border"
      allowHTML
      {...rest}
      appendTo="parent"
      visible={show}
      content={children}
    >
      <button type="submit" style={styles.toolbar} />
    </Tippy>
  );
};
