import type { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import type { NavLinkProps } from "react-router-dom";
import { Tippy } from "@circle-react/components/shared/Tippy";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";
import { ModalCloseButton } from "./ModalCloseButton";

export const navLinkStyles = {
  className:
    "block py-2 px-3 text-sm leading-5 normal-case bg-transparent rounded-md transition-colors duration-200 cursor-pointer select-none text-default hover:text-dark focus:text-dark",
  activeClassName: "!bg-tertiary !font-medium !text-dark",
};

interface NavItemProps {
  label: string;
  href: NavLinkProps["to"];
  replace?: boolean;
}

interface HeaderNavProps {
  titleNode?: ReactNode;
  title?: string;
  items?: NavItemProps[];
  rightNode?: ReactNode;
}

export const HeaderNav = ({
  titleNode,
  title,
  items = [],
  rightNode,
}: HeaderNavProps) => {
  const isSmScreen = useSmScreenMediaQuery();
  const { onClose, shouldShowCloseButton } = useModalData();
  return (
    <nav
      className="bg-primary flex h-full w-full flex-col items-start justify-between gap-3 !p-0 px-5 pb-2 pt-6 md:gap-4 md:px-6 md:py-4 lg:grid lg:!items-center"
      style={{
        gridTemplateColumns: "1fr 1fr 1fr",
      }}
    >
      <div className="flex w-full items-center justify-between">
        <div>
          {titleNode}
          {title && (
            <span className="flex w-full min-w-0">
              <Tippy content={title} className="truncate text-lg font-semibold">
                {title}
              </Tippy>
            </span>
          )}
        </div>
        {shouldShowCloseButton && isSmScreen && (
          <ModalCloseButton onClick={onClose} />
        )}
      </div>

      <div className="flex w-full items-center gap-6 overflow-auto lg:justify-center lg:overflow-visible">
        <ul className="flex list-none items-center gap-6">
          {items.map(item => (
            <li key={item.label}>
              <NavLink
                to={item.href}
                exact
                replace={item?.replace}
                {...navLinkStyles}
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      {rightNode && (
        <div className="flex w-full items-center justify-end">{rightNode}</div>
      )}
    </nav>
  );
};
