import type { ReactNode } from "react";
import classnames from "classnames";

interface HeaderProps {
  className?: string;
  children?: ReactNode;
}

export const Header = ({ className, children }: HeaderProps) => (
  <div
    className={classnames(
      "text-dark border-primary sticky flex items-center justify-between border-b px-5 pb-2 pt-6 md:px-6 md:py-5",
      className,
      {
        "!p-0": window?.webview?.isInsideMobileWebview,
      },
    )}
  >
    <div className="flex w-full justify-between">{children}</div>
  </div>
);
