import { memo } from "react";
import { Helmet } from "react-helmet";
import { useThemeContext } from "@circle-react/providers";

export const HighlightStylesByAppearance = memo(() => {
  const { isDarkAppearance } = useThemeContext();

  if (isDarkAppearance) {
    return (
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/github-dark.min.css"
          integrity="sha512-rO+olRTkcf304DQBxSWxln8JXCzTHlKnIdnMUwYvQa9/Jd4cQaNkItIUj6Z4nvW1dqK0SKXLbn9h4KwZTNtAyw=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/github.min.css"
        integrity="sha512-0aPQyyeZrWj9sCA46UlmWgKOP0mUipLQ6OZXu8l4IcAmD2u31EPEy9VcIMvl7SoAaKe8bLXZhYoMaE/in+gcgA=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
    </Helmet>
  );
});

HighlightStylesByAppearance.displayName = "HighlightStylesByAppearance";
