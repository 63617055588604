import PropTypes from "prop-types";
import { InlineBlockSelector } from "./InlineBlockSelector";
import { ModalBlockSelector } from "./ModalBlockSelector";

export const BlockSelector = ({ variant, ...props }) => {
  if (variant === "inline") {
    return <InlineBlockSelector {...props} />;
  }
  if (variant === "modal") {
    return <ModalBlockSelector {...props} />;
  }
  console.error("BlockSelector: Wrong value for variant = ", variant);
  return null;
};

BlockSelector.propTypes = {
  variant: PropTypes.oneOf(["inline", "modal"]),
};
