import { useEffect } from "react";
import { reduce } from "lodash";
import { useIsEmbedsFeatureEnabled } from "@circle-react-shared/TrixEditor/TrixEditorFeatureFlagContext";
import { useEditorApi } from "../TrixEditorContext";
import { embedContent } from "../helpers";

function isValidUrl(string) {
  if (string && string.includes(" ")) {
    return false;
  }
  try {
    const url = new URL(string);
    return ["https:", "http:"].includes(url.protocol);
  } catch (_) {
    return false;
  }
}

export const Autolink = () => {
  const { editorElement, trixApiWrapper } = useEditorApi();
  const isEmbedsFeatureEnabled = useIsEmbedsFeatureEnabled();
  useEffect(() => {
    if (!editorElement) return;
    if (!isEmbedsFeatureEnabled) return;

    const { editor } = editorElement;
    document.execCommand("AutoUrlDetect", false, false); // turn off browser's auto linking
    let previousValue = editor.getDocument().toString();
    let currentValue = editor.getDocument().toString();
    const onTrixChange = async event => {
      if (event.key !== "Enter") {
        return;
      }
      const attributesSkipList = [
        "number",
        "bullet",
        "heading2",
        "heading3",
        "code",
        "strike",
        "href",
      ];
      const shouldSkipAutolink = reduce(
        attributesSkipList,
        (shouldSkip, attribute) =>
          shouldSkip || trixApiWrapper.attributeIsActive(attribute),
        false,
      );
      if (shouldSkipAutolink) {
        return;
      }
      const documentText = editor.getDocument().toString();
      const cp = trixApiWrapper.getPosition();
      previousValue = currentValue;
      currentValue = documentText;
      if (
        previousValue.substring(0, cp + 1) === currentValue.substring(0, cp + 1)
      ) {
        return;
      }
      const lines = documentText.substring(0, cp).split("\n");
      const mayBeUrl = lines[lines.length - 2];
      const response = isValidUrl(mayBeUrl);
      if (response) {
        editor.setSelectedRange([cp - 1 - mayBeUrl.length, cp - 1]);
        editor.deleteInDirection("backward");
        embedContent({
          content: mayBeUrl,
          trixApiWrapper,
          skipRecordUndoEntry: true,
          skipLineBreak: true,
        });
      }
    };

    editorElement.addEventListener("keyup", onTrixChange);
    return () => {
      if (editorElement) {
        editorElement.removeEventListener("keyup", onTrixChange);
      }
    };
  }, [editorElement, isEmbedsFeatureEnabled]);

  return null;
};
