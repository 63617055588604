import Tippy from "@tippyjs/react";
import { t } from "@/i18n-js/instance";
import { Modal } from "@circle-react-uikit/Modal";
import { useMdScreenMediaQuery } from "../../../../hooks/useMediaQuery";
import { useEditorMode } from "../useEditorMode";
import { GiphySearchGrid } from "./GiphySearchGrid";
import { ToggleGiphyBtn } from "./ToggleGiphyBtn";

export const GiphyPicker = () => {
  const { setModeGiphyPicker, showGiphyPicker, resetEditorMode } =
    useEditorMode();

  const show = showGiphyPicker();

  const toggleGiphyPicker = event => {
    event.preventDefault();
    if (show) {
      resetEditorMode();
    } else {
      setModeGiphyPicker();
    }
  };

  const isMdScreen = useMdScreenMediaQuery();

  return (
    <>
      {!isMdScreen && (
        <>
          <Modal
            variant="large"
            className="fullpage-modal__gif"
            title={t("trix_editor.toolbar.gifs")}
            show={show}
            onClose={resetEditorMode}
          >
            <GiphySearchGrid show={show} onClose={resetEditorMode} />
          </Modal>
          <ToggleGiphyBtn onClick={toggleGiphyPicker} />
        </>
      )}
      {isMdScreen && (
        <Tippy
          content={<GiphySearchGrid show={show} onClose={resetEditorMode} />}
          visible={show}
          allowHTML
          interactive
          animation="false"
          duration={0}
          arrow
          delay={0}
          theme="light-border"
          appendTo="parent"
        >
          <div>
            <ToggleGiphyBtn onClick={toggleGiphyPicker} />
          </div>
        </Tippy>
      )}
    </>
  );
};
