import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import { Portal } from "@circle-react-uikit/Portal";
import { Modal_DEPRECATED } from "../Modal";
import { DownloadButton } from "./DownloadButton";
import { LeftArrow, RightArrow } from "./GalleryIcons";
import { PdfPreview } from "./PdfPreview";

export const AttachmentPreview = ({
  show,
  onClose,
  attachments,
  path,
  currentIndex = 0,
}) => {
  const [display, setDisplay] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [post, setPost] = useState();
  const [home, setHome] = useState();
  const [stateIndex, setStateIndex] = useState(currentIndex);

  useEffect(() => {
    // parse content on document load
    // Detect if Image click is a jpg or pdf
    const currentType = attachments[stateIndex].contentType;

    // Detect if we are on a post page (vs post/list/card view)
    const isPost = document.body.classList.contains("view-post");
    const isHome = document.body.classList.contains("view-homepage");

    if (isPost) setPost(true);
    else if (!isPost) setPost(false);

    if (isHome) setHome(true);
    else if (!isHome) setHome(false);

    if (currentType === "application/pdf") {
      setDisplay("pdf");
      setLoaded(true);
    } else if (
      currentType === "image/png" ||
      currentType === "image/jpeg" ||
      currentType === "image/jpg" ||
      currentType === "image/gif"
    ) {
      setDisplay("img");
      setLoaded(true);
    }
  }, []);

  const images = attachments
    .filter(attachment => attachment.contentType.startsWith("image/"))
    .map(attachment => ({
      original: attachment.url,
      thumbnail: attachment.url,
      filename: attachment.filename,
      url: attachment.url,
      sgid: attachment.sgid,
    }));

  useEffect(() => {
    if (window && document && show) {
      // Override Modal forcing scroll top
      const pos = window.scrollY;
      setTimeout(() => window.scroll(0, pos), 100);

      // Disable body scrolling
      document.body.classList.add("image-gallery--open");
    } else if (document && !show)
      // Re-enable body scrolling
      document.body.classList.remove("image-gallery--open");
  }, [show]);

  function allowScroll() {
    // Allow body scrolling on close
    document.body.classList.remove("image-gallery--open");
  }

  const gallery = useRef(null);

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        type="submit"
        className="image-gallery-icon image-gallery-left-nav"
        disabled={disabled}
        onClick={onClick}
      >
        <LeftArrow />
      </button>
    );
  }

  function renderRightNav(onClick, disabled) {
    return (
      <button
        type="submit"
        className="image-gallery-icon image-gallery-right-nav"
        disabled={disabled}
        onClick={onClick}
      >
        <RightArrow />
      </button>
    );
  }

  const handleClose = () => {
    onClose();
    allowScroll();
  };

  // Go to post url if attachment is image, and on post view
  if ((!post || home) && loaded && display === "img" && path) {
    window.location.href = window.location.origin + path;
    return null;
  }

  if (!loaded) return null;

  return (
    <Portal>
      <Modal_DEPRECATED
        show={show}
        onClose={handleClose}
        closeOnEscape
        closeOnOutsideClick
        fullscreen
        trixMode="trix-modal trix-modal__gallery"
      >
        <Modal_DEPRECATED.Header onClose={handleClose}>
          {display === "img" && <div>{images[stateIndex].filename}</div>}
          {display === "pdf" && <div>{attachments[stateIndex].filename}</div>}

          {display === "img" && <DownloadButton url={images[stateIndex].url} />}
          {display === "pdf" && (
            <DownloadButton url={attachments[stateIndex].url} />
          )}
        </Modal_DEPRECATED.Header>

        <Modal_DEPRECATED.Body>
          {display === "img" && (
            <ImageGallery
              items={images}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              ref={gallery}
              startIndex={currentIndex}
              onSlide={newIndex => setStateIndex(newIndex)}
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
              infinite={false}
              slideDuration={0}
              lazyLoad
            />
          )}
          {display === "pdf" && (
            <PdfPreview src={attachments.map(item => item.url)[stateIndex]} />
          )}
        </Modal_DEPRECATED.Body>
      </Modal_DEPRECATED>
    </Portal>
  );
};

AttachmentPreview.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  attachments: PropTypes.array,
  path: PropTypes.string,
  currentIndex: PropTypes.number,
};
