import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";
import { useAiSummaryContext } from "./Context";

interface AiSummaryContentProps {
  className?: string;
  closeButtonPosition?: "content" | "sidebar";
  children: React.ReactNode;
}

export const AiSummaryContent = ({
  children,
  className,
  closeButtonPosition = "content",
}: AiSummaryContentProps) => {
  const { isCollapsed, onCollapseClick } = useAiSummaryContext();

  return (
    <div className="relative flex gap-3">
      <div className={classNames("flex grow flex-col gap-2", className)}>
        {children}
      </div>
      {!isCollapsed && onCollapseClick && (
        <IconButton
          name="20-close"
          iconSize={20}
          onClick={onCollapseClick}
          variant="secondary"
          ariaLabel={t("collapse")}
          className={classNames(
            "bg-primary !border-primary rounded-full border",
            {
              "absolute -right-0.5 -top-0.5": closeButtonPosition === "content",
            },
          )}
        />
      )}
    </div>
  );
};
