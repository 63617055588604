import PropTypes from "prop-types";
import classNames from "classnames";

export const Main = ({ children, className = "", ...rest }) => (
  <main
    className={classNames(
      "bg-secondary grid-in-main h-full w-full overflow-auto p-8",
      className,
    )}
    {...rest}
  >
    {children}
  </main>
);

Main.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
