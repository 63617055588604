/*
  Ids used on this file:
  /app/assets/images/sprite/icons.svg
*/
export const ICONS_1 = [
  "mark-all-read",
  "menu-dots-vertical",
  "globe",
  "share",
  "share-v2",
  "analytics",
  "mute-notifications",
  "exit",
  "report",
  "api",
  "book",
  "arrow-solid-bottom-left",
  "arrow-solid-bottom-right",
  "reply",
  "arrow-line-bottom-left",
  "arrow-line-bottom-right",
  "arrow-line-top-left",
  "arrow-line-top-right",
  "arrow-solid-top-left",
  "arrow-solid-top-right",
  "attachment",
  "bell",
  "billing",
  "box",
  "box-check",
  "box-line",
  "calendar",
  "message-lines-center",
  "circle-add",
  "circle-check",
  "circle-checked",
  "circle-unchecked",
  "circle-dollar",
  "info",
  "code",
  "dashboard",
  "delete",
  "back",
  "dm",
  "download",
  "embed",
  "facebook",
  "facebook-alt",
  "file",
  "filter",
  "flag",
  "flagged",
  "image",
  "instagram",
  "star",
  "layers",
  "like",
  "linkedin",
  "linkedin-alt",
  "lock",
  "lock-solid",
  "mail",
  "map",
  "members",
  "menu-dots",
  "message",
  "message-center",
  "message-lines",
  "payments",
  "pencil",
  "profile",
  "search",
  "settings",
  "settings-v2",
  "suitcase",
  "tag",
  "twitter",
  "twitter-alt",
  "user",
  "add-user",
  "message-new",
  "home",
  "menu",
  "fullpage",
  "caret-down",
  "caret-down-solid",
  "caret-down-small",
  "caret-down-bold",
  "close",
  "close-v2",
  "pin",
  "information-outline",
  "link",
  "cheveron-up",
  "cheveron-down",
  "user-error",
  "user-delete",
  "topic",
  "add",
  "mention",
  "check",
  "arrow-right",
  "hash",
  "select-double-arrow",
  "attachment-pdf",
  "attachment-mp3",
  "attachment-video",
  "attachment-empty",
  "back-arrow",
  "back-arrow-v2",
  "hamburger",
  "toolbar-action",
  "toolbar-video",
  "toolbar-image",
  "toolbar-gif",
  "toolbar-file",
  "giphy",
  "block-menu-btn",
  "emoji",
  "clear",
  "new-direct-message",
  "circle-check--green",
  "bar-plus",
  "sort-arrows",
  "loader",
  "plus",
  "expand",
  "shrink",
  "cover",
  "smile",
  "space-members-only",
  "space-members-only-active",
  "space-private",
  "space-private-active",
  "space-open",
  "space-open-active",
  "notifications-none",
  "notifications-none-active",
  "notifications-email",
  "notifications-email-active",
  "notifications-app",
  "notifications-app-active",
  "plus-big",
  "posts-thumbnail",
  "events-thumbnail",
  "circle-checked-old",
  "circle-unchecked-old",
  "feed-thumbnail",
  "list-thumbnail",
  "card-thumbnail",
  "hangup",
  "fullscreen",
  "exit-fullscreen",
  "chat-activity",
  "session-info",
  "middot",
  "add-participants",
  "strong-signal",
  "weak-signal",
  "live-streams-arrow-left",
  "live-streams-arrow-right",
  "speaker-view",
  "gallery-view",
  "menu-check",
  "play",
  "checklist",
  "success-circle",
  "circle-empty",
  "eye-closed-solid",
  "map-pin",
  "post",
  "comment",
  "space",
  "card-amex",
  "card-diners",
  "card-discover",
  "card-jcb",
  "card-mastercard",
  "card-unionpay",
  "card-visa",
  "card-generic",
  "lightning",
  "members-v2",
  "home-v2",
  "cheveron-right",
  "camera",
  "camera-disabled",
  "microphone",
  "microphone-muted",
  "screen",
  "people",
  "chat",
  "video-settings",
  "notification-info",
  "exit-session",
  "question-mark",
  "pin-map",
  "at-symbol",
  "plus-v2",
  "rail-collapse-right",
  "rail-collapse-left",
  "space-chat-members",
  "sidebar-messages",
  "sidebar-bell",
  "search-v2",
  "filter-v2",
  "20-close",
  "20-back",
  "16-search",
  "16-account",
  "16-download",
  "16-chevron-down",
  "20-chevron-right",
  "20-menu-3-dot",
  "20-chevron-left",
  "icon-check-solid",
  "new-tab-link",
  "hash-v2",
  "lock-v2",
  "menu-dots-v2",
  "check-solid",
  "16-social-web",
  "20-calendar-number",
  "24-calendar-number",
  "20-calendar-recurring",
  "24-calendar-recurring",
  "tiptap-mention",
  "tiptap-emoji",
  "tiptap-send",
  "tiptap-image",
  "tiptap-gif",
  "tiptap-paperclip",
  "credit-card",
  "google-pay",
  "apple-pay",
  "live-stream-settings",
  "chevron-left",
  "settings-gear",
  "settings-account",
  "settings-plan",
  "settings-moderation",
  "bookmark-folder",
  "16-folder",
  "clipboard",
  "20-user",
  "20-arrow-left",
  "all-spaces",
  "recording",
  "16-trash-bin",
  "16-drag-handle",
  "20-desktop",
  "16-desktop",
  "16-tablet",
  "16-mobile",
  "12-check-circle",
  "20-mobile",
  "20-tablet",
  "16-character-square",
  "16-text-square",
  "16-select-square",
  "16-number-square",
  "16-checkbox-square",
  "16-facebook-square",
  "16-twitter-square",
  "16-linkedin-square",
  "16-instagram-square",
  "16-add",
  "16-calendar",
  "16-eye-closed",
  "16-eye-open",
  "16-check-circle",
  "20-brush",
  "20-settings",
  "20-add-user",
  "20-post",
  "20-comment",
  "courses",
  "20-plus-v2",
  "20-close-sm",
  "16-calendar",
  "16-location",
  "16-user",
  "16-question-mark",
  "20-sun",
  "20-moon",
  "16-grid",
  "bullet-list-text",
  "20-copy",
  "16-add-sm",
  "16-add-lg",
  "12-close",
  "16-clock",
  "16-sun",
  "16-moon",
  "16-palette",
  "16-invite-member",
  "16-settings",
  "16-analytics",
  "16-edit",
] as const;
