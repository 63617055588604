import PropTypes from "prop-types";
import classnames from "classnames";

export const FieldLabel = ({ field, label, description, className }) => (
  <div className={classnames("form-meta", className)}>
    <label htmlFor={field}>{label}</label>
    {description && (
      <div
        className="field-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    )}
  </div>
);

FieldLabel.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
};
