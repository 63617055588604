import type { ReactNode } from "react";
import { t } from "@/i18n-js/instance";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-uikit/Typography";

interface TitleWithBackButtonProps {
  children: ReactNode;
  onBack: () => void;
  backButtonAriaLabel?: string;
}

export function TitleWithBackButton({
  children,
  onBack,
  backButtonAriaLabel = t("back"),
}: TitleWithBackButtonProps) {
  return (
    <div className="divide-primary flex items-center divide-x">
      <IconButton
        name="20-arrow-left"
        iconSize={20}
        buttonSize={28}
        className="text-default me-4"
        iconClassName="w-5 h-5"
        onClick={onBack}
        ariaLabel={backButtonAriaLabel}
      />
      <div className="ps-4">
        <Typography.TitleSm weight="semibold" color="text-darkest">
          {children}
        </Typography.TitleSm>
      </div>
    </div>
  );
}
