import { t } from "@/i18n-js/instance";
import { formatPaywallCurrencyAmount } from "@/react/helpers/paywalls/currencies";
import { localeStringRoot } from "../constants";

export const useRefundChargeForm = ({ charge }) => {
  const maximumAmountToRefund = charge.amount_to_refund_raw;
  const removeAllDigits = e => e.replace(/\D/g, "");

  const transformValueIntoCurrency = e => {
    const amountInCents = Number(removeAllDigits(e));

    if (!isNaN(amountInCents)) {
      return formatPaywallCurrencyAmount(charge.currency, amountInCents, {
        includeCurrencyCode: false,
        forceMaxFractionDigits: true,
      });
    }
  };

  const minAmountToRefund = e => {
    const amountInCents = Number(removeAllDigits(e));

    return (
      amountInCents >= 1 ||
      t(`${localeStringRoot}.refund_form.alerts.minimun_amount_required`, {
        value: formatPaywallCurrencyAmount(charge.currency, 1),
      })
    );
  };

  const maxAmountToRefund = e => {
    const amountInCents = Number(removeAllDigits(e));

    return (
      amountInCents <= maximumAmountToRefund ||
      t(`${localeStringRoot}.refund_form.alerts.maximum_amount_required`, {
        value: formatPaywallCurrencyAmount(
          charge.currency,
          maximumAmountToRefund,
        ),
      })
    );
  };

  return {
    transformValueIntoCurrency,
    minAmountToRefund,
    maxAmountToRefund,
  };
};
