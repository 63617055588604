import { create } from "zustand";

interface ScheduledEmailModalState {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}

export const useScheduledEmailModalStore = create<ScheduledEmailModalState>(
  set => ({
    isDirty: false,
    setIsDirty: (isDirty: boolean) => set({ isDirty }),
  }),
);
