import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";

export const SaveButton = ({
  onClick,
  variant = "primary",
  formId,
  children,
  ...rest
}) => {
  const buttonProps = formId
    ? { form: formId, type: "submit" }
    : { type: "button" };

  return (
    <Button
      variant={variant}
      onClick={() => isFunction(onClick) && onClick()}
      {...buttonProps}
      {...rest}
    >
      {children || t("save")}
    </Button>
  );
};

SaveButton.propTypes = {
  formId: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  variant: PropTypes.oneOf(["primary", "secondary", "danger", "circle"]),
};
