import classNames from "classnames";
import { useThemeContext } from "@/react/providers";
import { useActiveSpaceMembersCount } from "@circle-react/contexts/useActiveSpaceMembersCount";
import { useScrollIntoView } from "@circle-react/hooks/utils/useScrollIntoView";
import type { Record } from "@circle-react-shared/CommunityMemberPicker/interfaces";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberCount } from "../../MemberCount";

export interface SpaceResultProps {
  record: Record;
  onClick?: (record: Record) => void;
  isSelected: boolean;
}

export const SpaceResult = ({
  record,
  onClick,
  isSelected,
}: SpaceResultProps) => {
  const ref = useScrollIntoView<HTMLButtonElement>(isSelected);
  const { currentAppearance } = useThemeContext();
  const {
    id,
    name,
    emoji,
    custom_emoji_url: customEmojiUrl,
    custom_emoji_dark_url: customEmojiDarkUrl,
  } = record;
  const { count } = useActiveSpaceMembersCount({ spaceId: id });

  const handleClick = () => {
    onClick?.(record);
  };

  return (
    <button
      type="button"
      className={classNames(
        "hover:bg-tertiary flex w-full cursor-pointer items-center gap-3 px-5 py-2 text-left first:pt-4 last:pb-4",
        { "bg-tertiary": isSelected },
      )}
      onClick={handleClick}
      ref={ref}
    >
      <EmojiRenderer
        className="!h-5 !w-5"
        emoji={emoji}
        appearance={currentAppearance}
        customEmojiUrl={customEmojiUrl}
        customEmojiDarkUrl={customEmojiDarkUrl}
      />
      <div className="grow">
        <Typography.LabelSm>{name}</Typography.LabelSm>
      </div>
      <MemberCount count={count} />
    </button>
  );
};
