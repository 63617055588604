import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { RefundChargeModalProps } from "./RefundChargeModal";
import { RefundChargeModal } from "./RefundChargeModal";

const RefundChargeModalComponent = ModalManager.create(
  (props: RefundChargeModalProps) => <RefundChargeModal {...props} />,
);

export const useCancelAndRefundChargeModal = () =>
  useModal(RefundChargeModalComponent);
