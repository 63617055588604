import type { ReactNode } from "react";
import classnames from "classnames";

interface FooterProps {
  className?: string;
  children?: ReactNode;
}

export const Footer = ({ className, children }: FooterProps) => (
  <div
    className={classnames(
      "border-primary sticky border-t p-5 md:px-6 md:py-4",
      className,
    )}
  >
    <div className="mx-auto flex w-full max-w-[45rem] items-center justify-between">
      {children}
    </div>
  </div>
);
