import type { ReactNode } from "react";

interface ContainerProps {
  children?: ReactNode;
}

export const Container = ({ children }: ContainerProps) => (
  <div
    className="bg-primary flex h-full w-full flex-col items-start justify-between gap-4 !p-0 px-6 py-4 lg:grid lg:!items-center"
    style={{
      gridTemplateColumns: "1fr 1fr 1fr",
    }}
  >
    {children}
  </div>
);
