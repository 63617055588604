import type { ComponentType } from "react";
import classnames from "classnames";
import { find } from "lodash";
import { t } from "@/i18n-js/instance";
import type { Space } from "@circle-react/types/Space";
import { useDropdown } from "../DropdownMenu";
import type { SpaceSelectButtonProps } from "./SpaceSelectButton";
import { SpaceSelectButton } from "./SpaceSelectButton";
import { SpacesDropdown } from "./SpacesDropdown";

export interface SpaceSelectComponentProps {
  spaces?: Space[];
  onChange?: (id: number) => void;
  value?: number;
  variant?: string;
  containerClassname?: string;
  dropdownClassName?: string;
  shouldShowEmoji?: boolean;
  defaultButtonLabel?: string;
  placeholder?: string;
  customButton?: ComponentType<SpaceSelectButtonProps>;
  shouldDisplayHomepage?: boolean;
}

export const SpaceSelectComponent = ({
  spaces,
  onChange,
  value,
  variant,
  containerClassname,
  dropdownClassName,
  shouldShowEmoji,
  defaultButtonLabel = t("select_space"),
  placeholder = t("search_space_placeholder"),
  customButton,
  shouldDisplayHomepage,
}: SpaceSelectComponentProps) => {
  const { open: isOpen, toggleDropdown, handleClose } = useDropdown(false);
  const selectedSpace = find(spaces, { id: value });
  const containerClassnames = classnames(
    "react-space-selector",
    {
      "form-control": variant === "form-input",
      "react-space-selector--open": isOpen,
    },
    containerClassname,
  );

  const SpaceSelectButtonComponent = customButton || SpaceSelectButton;

  return (
    <div className={containerClassnames} data-testid="space-selector">
      <SpaceSelectButtonComponent
        selectedSpace={selectedSpace}
        toggleDropdown={toggleDropdown}
        shouldShowEmoji={shouldShowEmoji}
        defaultLabel={defaultButtonLabel}
        variant={variant}
        shouldDisplayHomepage={shouldDisplayHomepage}
        value={value}
      />
      <SpacesDropdown
        placeholder={placeholder}
        handleClose={handleClose}
        open={isOpen}
        onChange={onChange}
        spaces={spaces}
        className={dropdownClassName}
        shouldDisplayHomepage={shouldDisplayHomepage}
      />
    </div>
  );
};
