import { nameToHslColor, nameToInitials } from "@circleco/ui-kit";
import { Typography } from "@circle-react-uikit/Typography";

interface CustomerColumnImageProps {
  avatar_url?: string;
  name: string;
}

/** @deprecated please use UserImage component from @circleco/ui-kit */
export const CustomerColumnImage = ({
  avatar_url,
  name,
}: CustomerColumnImageProps) => (
  <div className="grid">
    {avatar_url && (
      <img
        loading="lazy"
        alt=""
        className="z-0 col-start-1 row-start-1 h-10 w-10 rounded-lg object-cover"
        src={avatar_url}
      />
    )}
    <div
      className="col-start-1 row-start-1 flex h-10 w-10 items-center justify-center rounded-lg"
      style={{
        backgroundColor: nameToHslColor(name),
      }}
    >
      <Typography.LabelLg>
        <div className="text-c-sidebar-badge-text">{nameToInitials(name)}</div>
      </Typography.LabelLg>
    </div>
  </div>
);
