import { BrowserView } from "react-device-detect";
import {
  useLgScreenMediaQuery,
  useSmScreenMediaQuery,
} from "@/react/hooks/useMediaQuery";
import { useTrixEditorFeatureFlagContext } from "@circle-react-shared/TrixEditor/TrixEditorFeatureFlagContext";
import { AddLink } from "./AddLink";
import { BlockSelectorBtn } from "./BlockSelectorBtn";
import { EmojiPicker } from "./EmojiPicker";
import { FileUploader } from "./FileUploader";
import { GiphyPicker } from "./GiphyPicker";
import { MediaUploader } from "./MediaUploader";

export const Toolbar = () => {
  const isLgScreen = useLgScreenMediaQuery();
  const isSmScreen = useSmScreenMediaQuery();
  const { isTrixFeatureEnabled } = useTrixEditorFeatureFlagContext();

  const hasVideoUpload = isTrixFeatureEnabled("videoUpload");
  const hasFileUpload = isTrixFeatureEnabled("fileUploadLink");
  const hasGiphyPicker = isTrixFeatureEnabled("giphyPicker");

  return (
    <div className="toolbar">
      <BlockSelectorBtn />
      {isSmScreen && <AddLink />}
      {hasVideoUpload && isLgScreen && (
        <MediaUploader attribute="attachment_video" subtype="video" />
      )}
      <MediaUploader attribute="attachment_image" subtype="image" />
      {hasGiphyPicker && <GiphyPicker />}
      <BrowserView>
        <EmojiPicker />
      </BrowserView>
      {hasFileUpload && <FileUploader />}
    </div>
  );
};
