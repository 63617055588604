import * as HoverCard from "@radix-ui/react-hover-card";
import { ActivityCard, ScoreChip } from "../";
import type { ActivityScoreDetail } from "../utils";

export const HoverScoreChip = ({
  scoreDetail,
}: {
  scoreDetail: ActivityScoreDetail;
}) => (
  <HoverCard.Root openDelay={500}>
    <HoverCard.Trigger asChild>
      <div className="cursor-pointer">
        <ScoreChip activityScore={scoreDetail.activityScore} />
      </div>
    </HoverCard.Trigger>
    <HoverCard.Portal>
      <HoverCard.Content className="z-[1060]" sideOffset={10} align="start">
        <ActivityCard scoreDetail={scoreDetail} />
      </HoverCard.Content>
    </HoverCard.Portal>
  </HoverCard.Root>
);
