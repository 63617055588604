import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { UseQueryResult } from "react-query";
import invariant from "tiny-invariant";
import type { ActivityResponseMap } from "../utils";
import { useGetActivityScore } from "./useGetActivityScore";

type ScoreContextType = UseQueryResult<ActivityResponseMap>;

const ScoreContext = createContext<ScoreContextType | null>(null);
ScoreContext.displayName = "ScoreContext";

export function useScoreContext() {
  const context = useContext(ScoreContext);
  invariant(
    context,
    "useScoreContext must be used within a ScoreContextProvider",
  );
  return context;
}

export function ScoreContextProvider({
  ids = [],
  children,
}: {
  ids: number[];
  children: ReactNode;
}) {
  const reactQueryProps = useGetActivityScore({ ids });

  return (
    <ScoreContext.Provider value={reactQueryProps}>
      {children}
    </ScoreContext.Provider>
  );
}
