import PropTypes from "prop-types";
import classNames from "classnames";

export const Header = ({ children, className, ...rest }) => (
  <nav
    className={classNames(
      "bg-primary flex h-full max-h-16 w-full items-center justify-between px-6 py-4",
      className,
    )}
    {...rest}
  >
    {children}
  </nav>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
