import { useEffect } from "react";

export const useDisableWindowScroll = disableScroll => {
  useEffect(() => {
    if (disableScroll) {
      document.body.classList.add("disable-scroll");
      let counter =
        parseInt(document.body.dataset.disableScrollCounter, 10) || 1;
      counter += 1;
      document.body.dataset.disableScrollCounter = counter.toString();
    } else {
      let counter =
        parseInt(document.body.dataset.disableScrollCounter, 10) || 1;
      counter = counter - 1;
      if (counter <= 0) {
        document.body.classList.remove("disable-scroll");
      }
      document.body.dataset.disableScrollCounter = Math.max(
        counter,
        0,
      ).toString();
    }
    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [disableScroll]);
};
