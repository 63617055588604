import { useEffect } from "react";
import { noop } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import type { SpaceSelectComponentProps } from "./Component";
import { SpaceSelectComponent } from "./Component";
import "./styles.scss";

export interface SpaceSelectProps extends SpaceSelectComponentProps {
  name?: string;
  rules?: Record<string, unknown>;
  defaultValue?: number;
  onChange?: () => void;
}

interface ControllerRenderProps {
  field: {
    value: number;
    onChange: (value: number) => void;
  };
}

export const SpaceSelect = ({
  name = "",
  rules = {},
  defaultValue,
  onChange = noop,
  ...passThroughProps
}: SpaceSelectProps) => {
  const { control, setValue } = useFormContext();
  useEffect(() => {
    // Default value may take some time to load,
    // We're setting the value once it changes (becomes available)
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }: ControllerRenderProps) => (
        <SpaceSelectComponent
          {...passThroughProps}
          value={field.value}
          onChange={value => {
            field.onChange(value);
            onChange?.();
            setValue(name, value, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
          }}
        />
      )}
    />
  );
};
