import type { ComponentPropsWithoutRef } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

interface HeaderActionsProps extends ComponentPropsWithoutRef<"div"> {
  children: React.ReactNode;
  className?: string;
}

export const HeaderActions = ({
  children,
  className,
  ...rest
}: HeaderActionsProps): JSX.Element => (
  <div className={classNames("flex items-center gap-6", className)} {...rest}>
    {children}
  </div>
);
