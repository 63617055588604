import { useMemo } from "react";
import { UserImageStatus as UIKitUserImageStatus } from "@circleco/ui-kit";
import type { UserImageStatusProps as UIKitUserImageStatusProps } from "@circleco/ui-kit";
import { omit } from "lodash";
import { useFrame } from "react-frame-component";

const useGetPropertyValue = (property: string) => {
  const { document } = useFrame();
  return useMemo(() => {
    if (!document) return "";
    const { documentElement } = document;
    return getComputedStyle(documentElement).getPropertyValue(property);
  }, [document, property]);
};

export interface UserImageStatusProps
  extends Omit<UIKitUserImageStatusProps, "onlineColor" | "idleColor"> {
  onlineColor?: never;
  idleColor?: never;
}

export const UserImageStatus = (props: UserImageStatusProps) => {
  const customOnlineColor = useGetPropertyValue("--color-online-background");
  const rest = omit(props, ["onlineColor", "idleColor"]);

  return (
    <UIKitUserImageStatus
      {...rest}
      onlineColor={customOnlineColor}
      isFullRounded
    />
  );
};
