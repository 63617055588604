import React from "react";
import { FadingWord } from "./FadingWord";
import type { HtmlNode } from "./HtmlNode";

interface FadingHtmlNodeProps {
  node: HtmlNode;
  currentTokenIndex: number;
}

export const FadingHtmlNode = ({
  node,
  currentTokenIndex,
}: FadingHtmlNodeProps): JSX.Element | null => {
  if (node.type === "text") {
    const words = node.content.match(/\S+\s*/g) || [];
    const hasLeadingSpace = node.content.startsWith(" ");
    return (
      <>
        {hasLeadingSpace && <span> </span>}
        {words.map((word, idx) => {
          const wordPosition = (node.wordStart || 0) + idx;
          return (
            <FadingWord
              key={wordPosition}
              isVisible={wordPosition < currentTokenIndex}
            >
              {word}
            </FadingWord>
          );
        })}
      </>
    );
  }

  if (node.type === "element") {
    return React.createElement(
      node.tag || "span",
      (node.wordStart || 0) >= currentTokenIndex ? { className: "hidden" } : {},
      node.children?.map((child, idx) => (
        <FadingHtmlNode
          key={[node.tag, idx].join("-")}
          node={child}
          currentTokenIndex={currentTokenIndex}
        />
      )),
    );
  }

  return null;
};
