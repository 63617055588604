import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import type { IconType } from "./IconNames";

export type { IconType };

export * from "./IconNames";

export interface IconProps extends ComponentPropsWithoutRef<"svg"> {
  type: IconType;
  size?: number;
  viewBox?: string;
  useWithFillCurrentColor?: boolean;
  useWithStrokeCurrentColor?: boolean;
}

interface SizeProps {
  width?: number;
  height?: number;
  viewBox?: string;
}

export const Icon = ({
  type,
  className = "",
  size,
  viewBox = "0 0 24 24",
  useWithFillCurrentColor = false,
  useWithStrokeCurrentColor = false,
  "aria-hidden": ariaHidden = true,
  ...props
}: IconProps) => {
  let sizeProps: SizeProps = { viewBox };

  if (size) {
    sizeProps = {
      width: size,
      height: size,
      viewBox: `0 0 ${size} ${size}`,
    };
  }

  return (
    <svg
      className={classNames(
        `icon icon-${type}`,
        {
          "animate-spin": type === "loader",
        },
        className,
      )}
      aria-hidden={ariaHidden}
      {...props}
      {...sizeProps}
    >
      <use
        xlinkHref={`#icon-${type}`}
        className={classNames(
          { "!fill-current": useWithFillCurrentColor },
          { "!stroke-current": useWithStrokeCurrentColor },
        )}
      />
    </svg>
  );
};
