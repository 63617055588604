import type { ReactNode } from "react";
import classNames from "classnames";

export interface ToolbarActionProps {
  active: boolean;
  action: {
    label?: ReactNode;
    attribute?: string;
    options?: any;
  };
  onClick: (attribute?: string, options?: any) => void;
}

export const ToolbarAction = ({
  active,
  action,
  onClick,
}: ToolbarActionProps) => (
  <button
    type="button"
    className={classNames("toolbar-action-btn", {
      active: active,
    })}
    onClick={event => {
      event.stopPropagation();
      event.preventDefault();
      onClick(action?.attribute, action?.options);
    }}
  >
    {action?.label}
  </button>
);
