export const RECORD_TYPES = Object.freeze({
  COMMUNITY_MEMBER: "community_member",
  SPACE: "space",
  SPACE_GROUP: "space_group",
  MEMBER_TAG: "member_tag",
});

export const mapSearchResultsRecords = (
  records = [],
  includeAllResultTypes = false,
  selectionState = {},
) => {
  const {
    spaceGroups: selectedSpaceGroups = [],
    spaces: selectedSpaces = [],
    memberTags: selectedMemberTags = [],
    communityMembers: selectedCommunityMembers = [],
  } = selectionState;
  const selectedSpaceGroupIds = selectedSpaceGroups.map(record => record.id);
  const selectedSpaceIds = selectedSpaces.map(record => record.id);
  const selectedMemberTagIds = selectedMemberTags.map(record => record.id);
  const selectedCommunityMemberIds = selectedCommunityMembers.map(
    record => record.id,
  );
  let currentIndex = 0;
  const addIndex = record => ({ index: currentIndex++, ...record });

  const spaceGroups = includeAllResultTypes
    ? records
        .filter(
          record =>
            record.type === RECORD_TYPES.SPACE_GROUP &&
            !selectedSpaceGroupIds.includes(record.id),
        )
        .map(addIndex)
    : [];
  const spaces = includeAllResultTypes
    ? records
        .filter(
          record =>
            record.type === RECORD_TYPES.SPACE &&
            !selectedSpaceIds.includes(record.id),
        )
        .map(addIndex)
    : [];
  const memberTags = includeAllResultTypes
    ? records
        .filter(
          record =>
            record.type === RECORD_TYPES.MEMBER_TAG &&
            !selectedMemberTagIds.includes(record.id),
        )
        .map(addIndex)
    : [];
  const communityMembers = includeAllResultTypes
    ? records
        .filter(
          record =>
            record.type === RECORD_TYPES.COMMUNITY_MEMBER &&
            !selectedCommunityMemberIds.includes(record.id),
        )
        .map(addIndex)
    : records.map(addIndex);

  const combinedRecords = [
    ...spaceGroups,
    ...spaces,
    ...memberTags,
    ...communityMembers,
  ];
  const recordCount = combinedRecords.length;

  return {
    spaceGroups,
    spaces,
    memberTags,
    communityMembers,
    combinedRecords,
    recordCount,
  };
};
