import { ICONS_1 } from "./ICONS_1";
import { ICONS_2 } from "./ICONS_2";
import { ICONS_3 } from "./ICONS_3";
import { ICONS_4 } from "./ICONS_4";

export const ICONS = [...ICONS_1, ...ICONS_2, ...ICONS_3, ...ICONS_4];

export type IconType = (typeof ICONS)[number];

export const isIconType = (icon: string): icon is IconType => {
  const icons: string[] = ICONS;
  return icons.includes(icon);
};
