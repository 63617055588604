import PropTypes from "prop-types";
import classNames from "classnames";
import { If } from "../If";

export const ModalBody = ({ title, children, className }) => (
  <div className={classNames("modal__body", className)}>
    <If condition={!!title}>
      <h2 className="modal__title">${title}</h2>
    </If>
    <div className="modal__description">{children}</div>
  </div>
);

ModalBody.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};
