import { t } from "@/i18n-js/instance";
import { useThemeContext } from "@/react/providers";
import { useActiveSpaceMembersCount } from "@circle-react/contexts/useActiveSpaceMembersCount";
import type { Record } from "@circle-react-shared/CommunityMemberPicker/interfaces";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberCount } from "../../MemberCount";

export interface SpaceRecordProps {
  record: Record;
  onClick?: (record: Record) => void;
}

export const SpaceRecord = ({ record, onClick }: SpaceRecordProps) => {
  const {
    id,
    name,
    emoji,
    custom_emoji_url: customEmojiUrl,
    custom_emoji_dark_url: customEmojiDarkUrl,
  } = record;
  const { currentAppearance } = useThemeContext();
  const { count } = useActiveSpaceMembersCount({ spaceId: id });

  const handleClick = () => {
    onClick?.(record);
  };

  return (
    <button
      type="button"
      className="group flex w-full cursor-pointer items-baseline gap-3 p-2 text-left"
      onClick={handleClick}
    >
      <EmojiRenderer
        className="!h-5 !w-5"
        emoji={emoji}
        appearance={currentAppearance}
        customEmojiUrl={customEmojiUrl}
        customEmojiDarkUrl={customEmojiDarkUrl}
      />
      <Typography.LabelSm>{name}</Typography.LabelSm>
      <div className="grow">
        <MemberCount count={count} />
      </div>
      <div className="text-dark invisible group-hover:visible">
        <Typography.LabelSm>{t("remove")}</Typography.LabelSm>
      </div>
    </button>
  );
};
