export const ErrorMessages = ({ errors = {} }) => {
  const errorEntries = Object.entries(errors);
  if (!errorEntries.length) {
    return null;
  }

  return (
    <>
      {errorEntries.map(([name, error]) => (
        <div key={name} className="form-errors">
          {error.message}
        </div>
      ))}
    </>
  );
};
