import PropTypes from "prop-types";
import classNames from "classnames";

export const IconButton = ({ className, children, ...props }) => (
  <button
    type="button"
    className={classNames(
      "hover:bg-secondary h-5 w-5 items-center justify-center rounded-sm transition-colors",
      className,
    )}
    {...props}
  >
    {children}
  </button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
