import { useEffect } from "react";

/** @deprecated Please use "useClickAway" from "react-use" instead */
export const useDetectOutsideElementClick = (
  containerElement,
  callback,
  extraDependencies = [],
  condition,
) => {
  useEffect(() => {
    const outsideElementClickHandler = event => {
      if (!containerElement) {
        return;
      }
      const myCondition =
        condition ||
        (() =>
          document.contains(event.target) &&
          !containerElement.contains(event.target));

      if (myCondition(event)) {
        callback(event);
      }
    };
    window.addEventListener("mousedown", outsideElementClickHandler);
    return () =>
      window.removeEventListener("mousedown", outsideElementClickHandler);
  }, [callback, containerElement, ...extraDependencies]);
};
