import type { ReactNode } from "react";
import classnames from "classnames";

interface BodyProps {
  id?: string;
  className?: string;
  children?: ReactNode;
  wrapperClassName?: string;
}

export const Body = ({
  id = "fullscreen-modal-body",
  className,
  children,
  wrapperClassName = "max-w-screen-md",
}: BodyProps) => (
  <div
    id={id}
    className={classnames("text-dark h-full overflow-y-auto", className)}
  >
    <div className={classnames("mx-auto w-full", wrapperClassName)}>
      {children}
    </div>
  </div>
);
