import { createContext, useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";

const TrixEditorFeatureFlagContext = createContext();
TrixEditorFeatureFlagContext.displayName = "TrixEditorFeatureFlagContext";

const defaults = {
  embeds: true,
  videoUpload: true,
  fileUploadLink: true,
  giphyPicker: true,
  mentions: true,
};

export const TrixEditorFeatureFlagContextProvider = ({ value, children }) => {
  const newValue = useMemo(() => ({ ...defaults, ...value }), [value]);
  const isTrixFeatureEnabled = useCallback(
    feature => {
      if (feature in defaults) {
        return newValue[feature];
      }
      throw new Error(`Wrong feature flag for trix editor: ${feature}`);
    },
    [newValue],
  );

  return (
    <TrixEditorFeatureFlagContext.Provider
      value={{ ...newValue, isTrixFeatureEnabled }}
    >
      {children}
    </TrixEditorFeatureFlagContext.Provider>
  );
};

export const useTrixEditorFeatureFlagContext = () =>
  useContext(TrixEditorFeatureFlagContext);

export const useIsEmbedsFeatureEnabled = () =>
  !!useTrixEditorFeatureFlagContext().isTrixFeatureEnabled("embeds");

TrixEditorFeatureFlagContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
  value: PropTypes.object.isRequired,
};
