import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import { RECORD_TYPES } from "../helpers";

export const SELECTION_ACTIONS = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  INITIAL_FETCH_START: "INITIAL_FETCH_START",
  INITIAL_FETCH_SUCCESS: "INITIAL_FETCH_SUCCESS",
  INITIAL_FETCH_FAILURE: "INITIAL_FETCH_FAILURE",
  RESET: "RESET",
};

export const add = (record, includeAllResultTypes, shouldUpdateValue) => ({
  type: SELECTION_ACTIONS.ADD,
  payload: { record, includeAllResultTypes, shouldUpdateValue },
});

export const remove = (record, includeAllResultTypes) => ({
  type: SELECTION_ACTIONS.REMOVE,
  payload: { record, includeAllResultTypes },
});

export const reset = includeAllResultTypes => ({
  type: SELECTION_ACTIONS.RESET,
  payload: { includeAllResultTypes },
});

export const fetchSpaceGroups =
  (spaceGroupIds = [], includeAllResultTypes) =>
  dispatch => {
    if (spaceGroupIds?.length) {
      reactQueryGet(internalApi.spaceGroups.index())
        .then(response => {
          response?.records
            ?.filter(record => spaceGroupIds.includes(record.id))
            ?.forEach(record => {
              dispatch(
                add(
                  { ...record, type: RECORD_TYPES.SPACE_GROUP },
                  includeAllResultTypes,
                  false,
                ),
              );
            });
        })
        .catch(console.error);
    } else {
      return Promise.resolve();
    }
  };

export const fetchSpaces =
  (spaceIds = [], includeAllResultTypes) =>
  dispatch => {
    if (spaceIds?.length) {
      reactQueryGet(internalApi.spaces.index())
        .then(response => {
          response?.records
            ?.filter(record => spaceIds.includes(record.id))
            ?.forEach(record => {
              dispatch(
                add(
                  { ...record, type: RECORD_TYPES.SPACE },
                  includeAllResultTypes,
                  false,
                ),
              );
            });
        })
        .catch(console.error);
    } else {
      return Promise.resolve();
    }
  };

export const fetchMemberTags =
  (memberTagIds = [], includeAllResultTypes) =>
  dispatch => {
    if (memberTagIds?.length) {
      reactQueryGet(internalApi.memberTags.index({}))
        .then(response => {
          response?.records
            ?.filter(record => memberTagIds.includes(record.id))
            ?.forEach(record => {
              dispatch(
                add(
                  { ...record, type: RECORD_TYPES.MEMBER_TAG },
                  includeAllResultTypes,
                  false,
                ),
              );
            });
        })
        .catch(console.error);
    } else {
      return Promise.resolve();
    }
  };

export const fetchCommunityMembers =
  (communityMemberIds = [], includeAllResultTypes) =>
  dispatch => {
    if (communityMemberIds?.length) {
      reactQueryGet(
        internalApi.search.communityMembers.index({
          params: {
            filters: {
              id: communityMemberIds,
            },
          },
        }),
      )
        .then(response => {
          response?.records?.forEach(record => {
            dispatch(
              add(
                { ...record, type: RECORD_TYPES.COMMUNITY_MEMBER },
                includeAllResultTypes,
                false,
              ),
            );
          });
        })
        .catch(console.error);
    } else {
      return Promise.resolve();
    }
  };

export const fetchInitialRecords =
  (value, includeAllResultTypes) => dispatch => {
    const spaceGroupIds = value?.space_groups_ids || [];
    const spaceIds = value?.spaces_ids || [];
    const memberTagIds = value?.member_tags_ids || [];
    const communityMemberIds =
      (!includeAllResultTypes ? value : value?.members_ids) || [];

    dispatch({
      type: SELECTION_ACTIONS.INITIAL_FETCH_START,
      payload: {
        spaceGroupIds,
        spaceIds,
        memberTagIds,
        communityMemberIds,
      },
    });
    return Promise.all([
      dispatch(fetchSpaceGroups(spaceGroupIds, includeAllResultTypes)),
      dispatch(fetchSpaces(spaceIds, includeAllResultTypes)),
      dispatch(fetchMemberTags(memberTagIds, includeAllResultTypes)),
      dispatch(
        fetchCommunityMembers(communityMemberIds, includeAllResultTypes),
      ),
    ])
      .then(() => {
        dispatch({
          type: SELECTION_ACTIONS.INITIAL_FETCH_SUCCESS,
          payload: {
            spaceGroupIds,
            spaceIds,
            memberTagIds,
            communityMemberIds,
          },
        });
      })
      .catch(err => {
        console.error(err);
        dispatch({
          type: SELECTION_ACTIONS.INITIAL_FETCH_FAILURE,
          payload: {
            spaceGroupIds,
            spaceIds,
            memberTagIds,
            communityMemberIds,
            errorMessage: err?.message,
          },
        });
      });
  };
