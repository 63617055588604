import { useEffect, useState } from "react";
import { Icon } from "../Icon";

export interface PdfPreviewProps {
  src: string;
  className?: string;
}

export const PdfPreview = ({ src, className }: PdfPreviewProps) => {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    const getPdfBlobUrl = (src: string) => {
      void fetch(src)
        .then(t => t.blob())
        .then(blob => {
          setBlobUrl(URL.createObjectURL(blob));
        });
    };
    if (src) {
      getPdfBlobUrl(src);
    }
  }, [src]);

  if (!blobUrl) {
    return <Icon type="loader" />;
  }

  return <embed className={className} src={blobUrl} />;
};
