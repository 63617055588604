import { useMemo } from "react";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";
import { ProfileLink } from "./ProfileLink";

const USER_LINK_REGEX = /\[\[name:(["']?)([^,\]]*?)\1, id:([a-zA-Z0-9]+)\]\]/g;

interface TextWithUserLinksProps {
  text: string;
  relevantPosts?: Array<{
    title: string;
    url: string;
    reference_number: number;
  }>;
}

export const TextWithUserLinks = ({
  text,
  relevantPosts,
}: TextWithUserLinksProps) => {
  const parts = useMemo(() => {
    const result = [];
    let lastIndex = 0;
    let match;

    while ((match = USER_LINK_REGEX.exec(text)) !== null) {
      const [fullMatch, , name, id] = match;

      // Add text before match
      if (match.index > lastIndex) {
        result.push({
          type: "text" as const,
          content: text.slice(lastIndex, match.index),
          key: `text-${match.index}`,
        });
      }

      // Add the link
      result.push({
        type: "link" as const,
        name: name.trim(),
        id: id.trim(),
        key: `${name.trim()}-${id.trim()}`,
      });

      lastIndex = match.index + fullMatch.length;
    }

    // Add remaining text
    if (lastIndex < text.length) {
      result.push({
        type: "text" as const,
        content: text.slice(lastIndex),
        key: `text-${lastIndex}`,
      });
    }

    return result;
  }, [text]);

  return (
    <Typography.BodySm color="text-default dark:text-dark">
      {parts.map(part => {
        if (part.type === "link") {
          return <ProfileLink key={part.key} name={part.name} id={part.id} />;
        }
        return <span key={part.key}>{part.content}</span>;
      })}
      {relevantPosts && relevantPosts.length > 0 && (
        <span className="ml-1 inline-flex gap-1">
          {relevantPosts.map(post => (
            <TippyV2
              key={post.reference_number}
              content={post.title}
              tooltipTextClassName="bg-primary text-primary"
            >
              <span
                key={post.reference_number}
                className="text-default text-xxs bg-tertiary hover:bg-dark mr-1 mt-0.5 inline-flex h-4 w-4 items-center justify-center rounded-full px-1.5 py-0.5"
              >
                <a
                  href={post.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-default hover:text-default"
                >
                  {post.reference_number}
                </a>
              </span>
            </TippyV2>
          ))}
        </span>
      )}
    </Typography.BodySm>
  );
};
