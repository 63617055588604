import type { IconType } from "@circle-react-shared/Icon/IconNames";

export type ScoreTypes = "high" | "medium" | "low" | "disabled";

export type ActivityCardScoreTypes = Exclude<ScoreTypes, "disabled">;

export interface ActivityScore {
  label?: string;
  score?: number;
  activity: ScoreTypes;
}

interface ScoreBreakdownType {
  category: string;
  display_value: string;
  score: number;
  activity: ActivityCardScoreTypes;
}

export interface ActivityScoreResponse {
  community_member_id: number;
  activity_score: ActivityScore;
  score_breakdown: ScoreBreakdownType[];
}

export interface ActivityScoreDetail {
  activityScore: ActivityScore;
  scoreBreakdown?: ScoreBreakdownType[];
}

export type ActivityResponseMap = Map<number, ActivityScoreDetail>;

export const getScoreIcon = (scoreType: ScoreTypes): IconType => {
  const scores: { [key in ScoreTypes]: IconType } = {
    high: "16-smile",
    medium: "16-neutral",
    low: "16-sad",
    disabled: "16-disabled",
  };
  return scores[scoreType];
};

export interface ScoreDetail {
  score: number;
  label: string;
}
