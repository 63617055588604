import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Tippy } from "@circle-react-shared/Tippy";

export const DownloadButton = ({ url }) => (
  <Tippy
    content={t("download")}
    className="ml-2 flex items-center"
    appendTo="parent"
  >
    <a className="flex" href={url} target="_blank" rel="noopener, noreferrer">
      <Icon type="16-download" size={16} className="text-light" />
    </a>
  </Tippy>
);

DownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
};
